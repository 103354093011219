// PigPathBroadcastCommand.
//
// Class used as a container to encapsulate the PigPathBroadcastCommand database table data.
export class PigPathBroadcastCommand {
  // PigPath.PathId.
  public pathId: number;

  // Power mode (active).
  public powerModeBroadcastCommandNowEvent: string | undefined;
  public powerModeBroadcastCommandLaunchEvent: string | undefined;
  public powerModeBroadcastCommandReceiveEvent: string | undefined;

  // Power mode (low power).
  public powerModeLowPowerBroadcastCommandNowEvent: string | undefined;
  public powerModeLowPowerBroadcastCommandLaunchEvent: string | undefined;
  public powerModeLowPowerBroadcastCommandReceiveEvent: string | undefined;

  // Request file download.
  public requestFileDownloadBroadcastCommandNowEvent: string | undefined;
  public requestFileDownloadBroadcastCommandLaunchEvent: string | undefined;
  public requestFileDownloadBroadcastCommandReceiveEvent: string | undefined;

  // Frequency settings (mag only).
  public frequencySettingsMagOnlyBroadcastCommandNowEvent: string | undefined;
  public frequencySettingsMagOnlyBroadcastCommandLaunchEvent:
    | string
    | undefined;
  public frequencySettingsMagOnlyBroadcastCommandReceiveEvent:
    | string
    | undefined;

  // Frequency settings (elf and mag).
  public frequencySettingsElfMagBroadcastCommandNowEvent: string | undefined;
  public frequencySettingsElfMagBroadcastCommandLaunchEvent: string | undefined;
  public frequencySettingsElfMagBroadcastCommandReceiveEvent:
    | string
    | undefined;

  // Frequency settings (elf only).
  public frequencySettingsElfOnlyBroadcastCommandNowEvent: string | undefined;
  public frequencySettingsElfOnlyBroadcastCommandLaunchEvent:
    | string
    | undefined;
  public frequencySettingsElfOnlyBroadcastCommandReceiveEvent:
    | string
    | undefined;

  // Format disk.
  public formatDiskBroadcastCommandNowEvent: string | undefined;
  public formatDiskBroadcastCommandLaunchEvent: string | undefined;
  public formatDiskBroadcastCommandReceiveEvent: string | undefined;

  // Constructor.
  public constructor(pathId: number) {
    this.pathId = pathId;
  }
}
