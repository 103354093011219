import { CommandType } from './CommandType';

export interface ICommand {
  uid: number;
  commandType: CommandType;
  timeStampSent: Date;
  commandId?: number;
  parameters?: object;
}

export class Command implements ICommand {
  constructor(
    public uid: number,
    public commandType: CommandType,
    public timeStampSent: Date,
    public parameters?: object,
    public commandId?: number
  ) {}
}
