import Vue from 'vue';

import './styles/quasar.styl';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import {
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QDialog,
  ClosePopup,
  QCard,
  QCardSection,
  QCardActions,
  QMenu,
  QSeparator,
  QForm,
  QInput,
  QAvatar,
  QTable,
  QTh,
  QTr,
  QTd,
  QSpace,
  QSelect,
  Notify,
  QExpansionItem,
  QTabs,
  QTab,
  QTabPanel,
  QTabPanels,
  QDate,
  QPopupProxy,
  QToggle,
  QFile,
  QOptionGroup,
  QTime,
  QCheckbox,
  Ripple,
  QSlider,
  QBtnDropdown,
  Dialog,
  QRadio,
  QMarkupTable,
  QBanner,
  QBadge,
  QTooltip,
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QMenu,
    QSeparator,
    QForm,
    QInput,
    QAvatar,
    QTable,
    QTh,
    QTr,
    QTd,
    QSpace,
    QSelect,
    QExpansionItem,
    QTabs,
    QTab,
    QTabPanel,
    QTabPanels,
    QDate,
    QPopupProxy,
    QToggle,
    QFile,
    QOptionGroup,
    QTime,
    QCheckbox,
    QSlider,
    QBtnDropdown,
    QRadio,
    QMarkupTable,
    QBanner,
    QBadge,
    QTooltip,
  },
  directives: {
    ClosePopup,
    Ripple,
  },
  plugins: {
    Notify,
    Dialog,
  },
  config: {
    notify: {
      /* Notify defaults */
    },
  },
  extras: ['fontawesome-v5'],
  framework: {
    iconSet: 'fontawesome-v5',
  },
});
