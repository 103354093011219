import Vue from 'vue';
import { PigPath } from '../../models/PigPath';
import { PigRun } from '../../models/PigRun';
import { MarkerPassage } from '../../models/MarkerPassage';
import { Passage } from '../../models/Passage';
import { AgmMarker } from '../../models/AgmMarker';
import { UserNotification } from '../../models/UserNotification';
import PigRunService from '../../services/PigRunService';
import DeviceService from '../../services/DeviceService';
import DateCommon from '../../utils/DateCommon';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  state: {
    selectedPigPath: null,
    pigPaths: [],
    activePigRuns: [],
    completedPigRuns: [],
    missedPassages: [],
    isReportLoading: false,
    isRCompRunSynching: false,
    ignoreNextPigRunUpdate: false,
    autoLinkDistance: 100, // meters
    autoVerifyMinutes: 5, // minutes away to enable autoVerify
  },
  mutations: {
    SET_MISSED_PASSAGES(state: any, payload: Passage[]) {
      state.missedPassages = payload;
    },
    CLEAR_MISSED_PASSAGES(state: any) {
      state.missedPassages = [];
    },
    REMOVE_MISSED_PASSAGE(state: any, payload: Passage) {
      state.missedPassages = state.missedPassages.filter(
        (p: Passage) => p.passageId !== payload.passageId
      );
    },
    SET_COMPLETED_PIG_RUNS(state: any, payload: PigRun[]) {
      state.completedPigRuns = payload;
    },
    SET_PIG_PATHS(state: any, payload: PigPath[]) {
      state.pigPaths = payload;
    },
    ADD_PIG_PATH(state: any, payload: PigPath) {
      // Mitch experiment of invoking a mutation directly from a component.
      console.log('Mitch - Mutation ADD_PIG_PATH called.');

      state.pigPaths.push(payload);
    },
    DELETE_PIG_PATH(state: any, payload: number) {
      console.log('Mitch - Mutation DELETE_PIG_PATH called.');

      state.pigPaths = state.pigPaths.filter(
        (pigPath: PigPath) => pigPath.pathId !== payload
      );
    },
    SET_SELECTED_PIG_PATH(state: any, payload: PigPath) {
      state.selectedPigPath = payload;
    },
    LAUNCH_PIG_RUN(state: any, payload: PigRun) {
      let idDuplicate = false;
      for (let i = 0; i < state.activePigRuns.length; i++) {
        if (state.activePigRuns[i].runId == payload.runId) {
          idDuplicate = true;
        }
      }
      if (idDuplicate == false) {
        state.activePigRuns.push(payload);
      }
    },
    CANCEL_PIG_RUN(state: any, runId: number) {
      state.activePigRuns = state.activePigRuns.filter(
        (pigRun: PigRun) => pigRun.runId !== runId
      );
    },
    CLOSE_PIG_RUNS(state: any) {
      state.activePigRuns = [];
    },
    SAVE_PIG_RUN(state: any, payload: PigRun) {
      state.completedPigRuns.push(payload);
    },
    DELETE_PIG_RUN(state: any, runId: number) {
      state.completedPigRuns = state.completedPigRuns.filter(
        (pigRun: PigRun) => pigRun.runId !== runId
      );
    },
    SET_REPORT_LOADING_STATUS(state: any, isReportLoading: boolean) {
      state.isReportLoading = isReportLoading;
    },
    SET_REPORT_SYNCH_STATUS(state: any, isRCompRunSynching: boolean) {
      state.isRCompRunSynching = isRCompRunSynching;
    },
    UPDATE_ACTIVE_PIG_RUN(state: any, payload: PigRun) {
      console.log('PigRun.ts. Enter mutation = [UPDATE_ACTIVE_PIG_RUN].');
      console.log(payload);

      const updateIdx = state.activePigRuns.findIndex(
        (pigRun: PigRun) => pigRun.runId === payload.runId
      );

      // Vue.set used to ensure reactivity when updating an array element directly using an index.
      Vue.set(state.activePigRuns, updateIdx, payload);
    },
    IGNORE_NEXT_PIG_RUN_UPDATE(state: any, ignore: boolean) {
      state.ignoreNextPigRunUpdate = ignore;
    },
    UPDATE_COMPLETED_RUN(state: any, payload: PigRun) {
      const updateIdx = state.completedPigRuns.findIndex(
        (pigRun: PigRun) => pigRun.runId === payload.runId
      );
      Vue.set(state.completedPigRuns, updateIdx, payload);
    },
  },
  actions: {
    restorePigData(context: any, payload: any) {
      PigRunService.getPigPaths().then((response) => {
        const pigPaths: PigPath[] = [];

        response.data.forEach((pigPath: any) => {
          const newMarkers: AgmMarker[] = [];

          if (pigPath.markers) {
            for (const m of JSON.parse(pigPath.markers)) {
              const newMarker = new AgmMarker(
                m.name,
                m.lat,
                m.lon,
                m.state,
                m.note,
                m.linkedUid,
                m.description,
                m.distanceMetres,
                m.autoLink ? true : false,
                m.autoVerify ? true : false,
                m.autoLinkedUids ? m.autoLinkedUids : [],
                m.ETA,
                m.DistanceFromLaunch
              );

              newMarkers.push(newMarker);
            }
          }

          const userNotifications: UserNotification[] = [];
          const userNotifcationsJson = JSON.parse(pigPath.notifications);

          if (userNotifcationsJson) {
            for (const n of userNotifcationsJson) {
              const newNotification = new UserNotification(
                n.name,
                n.userId,
                n.launch,
                n.receive,
                n.medium ? n.medium : 'sms',
                n.passageType,
                n.manualPassage,
                n.allMarkers,
                n.markers ? n.markers : undefined
              );

              userNotifications.push(newNotification);
            }
          }

          const newPigPath = new PigPath(
            pigPath.pathId,
            pigPath.pigRunName,
            pigPath.launchSiteLat,
            pigPath.launchSiteLon,
            pigPath.recieveSiteLat,
            pigPath.recieveSiteLon,
            JSON.parse(pigPath.agms),
            JSON.parse(pigPath.offlineAgms),
            JSON.parse(pigPath.pigs),
            pigPath.expectedSpeed,
            pigPath.pigLineGenerated
              ? JSON.parse(pigPath.pigLineGenerated)
              : undefined,
            pigPath.pigLineKml ? JSON.parse(pigPath.pigLineKml) : undefined,
            pigPath.notes,
            newMarkers,
            pigPath.csvFileName,
            userNotifications
          );

          pigPaths.push(newPigPath);
        });

        context.commit('SET_PIG_PATHS', pigPaths);
      });

      PigRunService.getPigRuns().then((response) => {
        const pigRuns: PigRun[] = [];

        response.data.forEach((pigRun: any) => {
          const newMarkers: AgmMarker[] = [];

          if (pigRun.markers) {
            for (const m of JSON.parse(pigRun.markers)) {
              const newMarker = new AgmMarker(
                m.name,
                m.lat,
                m.lon,
                m.state,
                m.note,
                m.linkedUid,
                m.description,
                m.distanceMetres,
                m.autoLink ? true : false,
                m.autoVerify ? true : false,
                m.autoLinkedUids ? m.autoLinkedUids : [],
                m.ETA,
                m.DistanceFromLaunch
              );

              newMarkers.push(newMarker);
            }
          }

          const newPassages: MarkerPassage[] = [];

          if (pigRun.passages) {
            for (const p of JSON.parse(pigRun.passages)) {
              const newPass = new MarkerPassage(
                p.markerName,
                p.passageTimeStamp,
                p.uid,
                p.isVerified,
                p.passageId,
                p.passageDetectedFrequency,
                p.detectionCount,
                p.packetComplexInformation,
                p.passageTimeEndOfSnip,
                p.data,
                p.elfId,
                p.geoId,
                p.magId
              );

              newPassages.push(newPass);
            }
          }

          const newPigPath = new PigPath(
            JSON.parse(pigRun.path).pathId,
            JSON.parse(pigRun.path).pigRunName,
            JSON.parse(pigRun.path).launchSiteLat,
            JSON.parse(pigRun.path).launchSiteLon,
            JSON.parse(pigRun.path).recieveSiteLat,
            JSON.parse(pigRun.path).recieveSiteLon,
            JSON.parse(pigRun.path).agms,
            JSON.parse(pigRun.path).offlineAgms,
            JSON.parse(pigRun.path).pigs,
            JSON.parse(pigRun.path).expectedSpeed,
            JSON.parse(pigRun.path).pigLineGenerated,
            JSON.parse(pigRun.path).pigLineKml,
            JSON.parse(pigRun.path).notes,
            JSON.parse(pigRun.path).markers,
            JSON.parse(pigRun.path).csvFileName,
            JSON.parse(pigRun.path).notifications
          );

          const newPigRun = new PigRun(
            pigRun.runId,
            newPigPath,
            pigRun.pig,
            DateCommon.sqlServerToDate(pigRun.launchTime),
            pigRun.recieveTime
              ? DateCommon.sqlServerToDate(pigRun.recieveTime)
              : undefined,
            pigRun.calculatedSpeed,
            newPassages,
            newMarkers,
            pigRun.currentPigLocationLat,
            pigRun.currentPigLocationLon,
            pigRun.PigEditTime,
            pigRun.EditNextMarkerIndex,
            pigRun.NewSpeed,
            pigRun.EditPigLocLat,
            pigRun.EditPigLocLon,
            pigRun.IsEdit,
            pigRun.EditNextMarkerIndex2,
            pigRun.Phase
          );

          pigRuns.push(newPigRun);
        });

        // split into active or completed
        const activePigRuns: PigRun[] = [];
        const completedPigRuns: PigRun[] = [];

        pigRuns.forEach((pigRun: PigRun) => {
          if (pigRun.recieveTime) {
            completedPigRuns.push(pigRun);
          } else {
            activePigRuns.push(pigRun);
          }
        });

        context.commit('SET_COMPLETED_PIG_RUNS', completedPigRuns);

        // relaunch pig runs that are active so they go through the same setup process
        if (activePigRuns.length > 0) {
          for (let i = 0; i < activePigRuns.length; i++) {
            context.dispatch('launchPig', activePigRuns[i]);
          }

          // context.dispatch('launchPig', activePigRuns[0]);
          // activePigRuns.forEach((pigRun: PigRun) => {
          //     context.dispatch('launchPig', pigRun);
          // });
        } else {
          context.dispatch('pigRunsReady');
        }
      });
    },

    restorePigRun(context: any, runId: number) {
      console.log(
        'PigRun.ts. Action = [restorePigRun]. RunId = [' + runId + '].'
      );

      PigRunService.getPigRun(runId)
        .then((response) => {
          if ('runId' in response.data) {
            const pigRun = response.data;
            const newMarkers: AgmMarker[] = [];

            console.log(
              'PigRun.ts. Action = [restorePigRun]. Before logging pigRun.markers object.'
            );
            console.log(pigRun.markers);

            if (pigRun.markers) {
              for (const m of JSON.parse(pigRun.markers)) {
                const newMarker = new AgmMarker(
                  m.name,
                  m.lat,
                  m.lon,
                  m.state,
                  m.note,
                  m.linkedUid,
                  m.description,
                  m.distanceMetres,
                  m.autoLink ? true : false,
                  m.autoVerify ? true : false,
                  m.autoLinkedUids ? m.autoLinkedUids : [],
                  m.ETA,
                  m.DistanceFromLaunch
                );

                console.log(
                  'PigRun.ts. Action = [restorePigRun]. newMarker.state = [' +
                    newMarker.state +
                    '].'
                );

                newMarkers.push(newMarker);
              }
            }

            const newPassages: MarkerPassage[] = [];

            if (pigRun.passages) {
              for (const p of JSON.parse(pigRun.passages)) {
                const newPass = new MarkerPassage(
                  p.markerName,
                  p.passageTimeStamp,
                  p.uid,
                  p.isVerified,
                  p.passageId,
                  p.passageDetectedFrequency,
                  p.detectionCount,
                  p.packetComplexInformation,
                  p.passageTimeEndOfSnip,
                  p.data,
                  p.elfId,
                  p.geoId,
                  p.magId
                );

                newPassages.push(newPass);
              }
            }

            const newPigPath = new PigPath(
              JSON.parse(pigRun.path).pathId,
              JSON.parse(pigRun.path).pigRunName,
              JSON.parse(pigRun.path).launchSiteLat,
              JSON.parse(pigRun.path).launchSiteLon,
              JSON.parse(pigRun.path).recieveSiteLat,
              JSON.parse(pigRun.path).recieveSiteLon,
              JSON.parse(pigRun.path).agms,
              JSON.parse(pigRun.path).offlineAgms,
              JSON.parse(pigRun.path).pigs,
              JSON.parse(pigRun.path).expectedSpeed,
              JSON.parse(pigRun.path).pigLineGenerated,
              JSON.parse(pigRun.path).pigLineKml,
              JSON.parse(pigRun.path).notes,
              JSON.parse(pigRun.path).markers,
              JSON.parse(pigRun.path).csvFileName,
              JSON.parse(pigRun.path).notifications
            );

            const newPigRun = new PigRun(
              pigRun.runId,
              newPigPath,
              pigRun.pig,
              DateCommon.sqlServerToDate(pigRun.launchTime),
              pigRun.recieveTime
                ? DateCommon.sqlServerToDate(pigRun.recieveTime)
                : undefined,
              pigRun.calculatedSpeed,
              newPassages,
              newMarkers,
              pigRun.currentPigLocationLat,
              pigRun.currentPigLocationLon,
              pigRun.PigEditTime,
              pigRun.EditNextMarkerIndex,
              pigRun.NewSpeed,
              pigRun.EditPigLocLat,
              pigRun.EditPigLocLon,
              pigRun.IsEdit,
              pigRun.EditNextMarkerIndex2,
              pigRun.Phase
            );

            if (newPigRun.recieveTime) {
              // this pig run has been ended by another user
              // update this state only
              context.commit('SAVE_PIG_RUN', newPigRun);
              context.commit('CANCEL_PIG_RUN', runId);
            } else {
              console.log(
                'PigRun.ts. Action = [restorePigRun]. Before calling mutation = [UPDATE_ACTIVE_PIG_RUN].'
              );

              // this will automatically update the PigRunMarkers component
              context.commit('UPDATE_ACTIVE_PIG_RUN', newPigRun);
            }
          } else {
            // if no data has been returned this has been canceled by another user
            context.commit('CANCEL_PIG_RUN', runId);
          }
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },

    restoreCompletedPigRun(context: any, runId: number) {
      console.log('restoreCompletedPigRun ' + runId);

      PigRunService.getPigRun(runId)
        .then((response) => {
          if ('runId' in response.data) {
            const pigRun = response.data;
            const newMarkers: AgmMarker[] = [];

            if (pigRun.markers) {
              for (const m of JSON.parse(pigRun.markers)) {
                const newMarker = new AgmMarker(
                  m.name,
                  m.lat,
                  m.lon,
                  m.state,
                  m.note,
                  m.linkedUid,
                  m.description,
                  m.distanceMetres,
                  m.autoLink ? true : false,
                  m.autoVerify ? true : false,
                  m.autoLinkedUids ? m.autoLinkedUids : [],
                  m.ETA,
                  m.DistanceFromLaunch
                );

                newMarkers.push(newMarker);
              }
            }

            const newPassages: MarkerPassage[] = [];

            if (pigRun.passages) {
              for (const p of JSON.parse(pigRun.passages)) {
                const newPass = new MarkerPassage(
                  p.markerName,
                  p.passageTimeStamp,
                  p.uid,
                  p.isVerified,
                  p.passageId,
                  p.passageDetectedFrequency,
                  p.detectionCount,
                  p.packetComplexInformation,
                  p.passageTimeEndOfSnip,
                  p.data,
                  p.elfId,
                  p.geoId,
                  p.magId
                );

                newPassages.push(newPass);
              }
            }

            const newPigPath = new PigPath(
              JSON.parse(pigRun.path).pathId,
              JSON.parse(pigRun.path).pigRunName,
              JSON.parse(pigRun.path).launchSiteLat,
              JSON.parse(pigRun.path).launchSiteLon,
              JSON.parse(pigRun.path).recieveSiteLat,
              JSON.parse(pigRun.path).recieveSiteLon,
              JSON.parse(pigRun.path).agms,
              JSON.parse(pigRun.path).offlineAgms,
              JSON.parse(pigRun.path).pigs,
              JSON.parse(pigRun.path).expectedSpeed,
              JSON.parse(pigRun.path).pigLineGenerated,
              JSON.parse(pigRun.path).pigLineKml,
              JSON.parse(pigRun.path).notes,
              JSON.parse(pigRun.path).markers,
              JSON.parse(pigRun.path).csvFileName,
              JSON.parse(pigRun.path).notifications
            );

            const newPigRun = new PigRun(
              pigRun.runId,
              newPigPath,
              pigRun.pig,
              DateCommon.sqlServerToDate(pigRun.launchTime),
              pigRun.recieveTime
                ? DateCommon.sqlServerToDate(pigRun.recieveTime)
                : undefined,
              pigRun.calculatedSpeed,
              newPassages,
              newMarkers,
              pigRun.currentPigLocationLat,
              pigRun.currentPigLocationLon,
              pigRun.PigEditTime,
              pigRun.EditNextMarkerIndex,
              pigRun.NewSpeed,
              pigRun.EditPigLocLat,
              pigRun.EditPigLocLon,
              pigRun.IsEdit,
              pigRun.EditNextMarkerIndex2,
              pigRun.Phase
            );

            if (newPigRun.recieveTime) {
              // this pig run has been ended by another user
              // update this state only
              context.commit('UPDATE_COMPLETED_RUN', newPigRun);
              context.dispatch('updateCompRun', newPigRun);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },

    showPigRunEditor(context: any, payload: any) {
      context.commit('SHOW_PIG_RUN_EDITOR', payload);

      if (payload) {
        context.commit('SET_SELECTED_PIG_PATH', payload); // Pig Run Edit
      } else {
        context.commit('SET_SELECTED_PIG_PATH', null); // Pig Run New
      }
    },
    hidePigRunEditor(context: any, payload: any) {
      context.commit('HIDE_PIG_RUN_EDITOR', payload);
    },
    showPigRunManager(context: any, payload: any) {
      context.commit('SHOW_PIG_RUN_MANAGER', payload);
    },
    hidePigRunManager(context: any, payload: any) {
      context.commit('HIDE_PIG_RUN_MANAGER', payload);
    },

    showReportManager(context: any, payload: any) {
      context.commit('SHOW_REPORT_MANAGER', payload);
    },

    showBroadcastCommands(context: any, payload: any) {
      context.commit('SHOW_BROADCAST_COMMANDS', payload);
    },

    upsertPigRun(context: any, payload: PigRun) {
      console.log('PigRun: upsertPigRun');
      if (payload.runId === -1) {
        console.log('PigRun: Add New PigRun');
        PigRunService.addPigRun(payload)
          .then((response) => {
            // console.log(response);
            payload.runId = response.data;
            // launching the pig run adds it to activePigRuns
            context.dispatch('launchPig', payload);
            context.dispatch('sendPigRunUpdateMsg', payload.runId);
          })
          .catch((error) => {
            console.error(error);
            context.dispatch('notify', 'Unable to launch Pig Run');
            // clear the local state and resync
            context.commit('CLOSE_PIG_RUNS').then(() => {
              context.dispatch('restorePigData');
            });
          });
      } else {
        console.log('PigRun: Update Pig Run. RunId = ' + payload.runId);

        PigRunService.addPigRun(payload)
          .then((response) => {
            // console.log(response);
            // all others will get the message to update, the user that update it can ignore it
            context.dispatch('sendPigRunUpdateMsg', payload.runId);
          })
          .catch((error) => {
            console.error(error);
            context.dispatch('notify', 'Unable to update Pig Run');
            // clear the local state and resync
            context.commit('CLOSE_PIG_RUNS').then(() => {
              context.dispatch('restorePigData');
            });
          });
      }
    },
    upsertFinishedPigRun(context: any, payload: PigRun) {
      console.log('PigRun: upsertFinishedPigRun');
      console.log('PigRun: Update Pig Run. RunId = ' + payload.runId);

      PigRunService.updateFinishedPigRun(payload)
        .then((response) => {
          // console.log(response);
          // all others will get the message to update, the user that update it can ignore it
          context.dispatch('sendPigRunUpdateMsg', payload.runId);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', 'Unable to update Pig Run');
          // clear the local state and resync
          context.commit('CLOSE_PIG_RUNS').then(() => {
            context.dispatch('restorePigData');
          });
        });
    },
    SynchFinishedPigRun(context: any, payload: number) {
      console.log('PigRun: SynchFinishedPigRun');
      console.log('PigRun: Synch Pig Run. RunId = ' + payload);
      context.commit('SET_REPORT_SYNCH_STATUS', true);
      PigRunService.synchFinishedPigRun(payload)
        .then((response) => {
          // console.log(response);
          // all others will get the message to update, the user that update it can ignore it
          // context.dispatch('sendPigRunUpdateMsg', payload.runId);
          // context.dispatch('passEditorRunId', payload);
          context.dispatch('restoreCompletedPigRun', payload);
          context.dispatch('sendPigRunUpdateMsg', payload);
          context.commit('SET_REPORT_SYNCH_STATUS', false);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', 'Unable to Synch Pig Run');
          // clear the local state and resync
          context.commit('CLOSE_PIG_RUNS').then(() => {
            context.dispatch('restorePigData');
          });
        });
    },
    ignoreNextPigRunUpdate(context: any, ignore: boolean) {
      context.commit('IGNORE_NEXT_PIG_RUN_UPDATE', ignore);
    },
    updatePigRunAgms(context: any, pigRun: PigRun) {
      PigRunService.updateAgms(pigRun)
        .then((response) => {
          console.log(response);
          context.dispatch('sendPigRunUpdateMsg', pigRun.runId);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', 'Unable to update Pig Run notifications');
          // clear the local state and resync
          // context.commit('CLOSE_PIG_RUNS');
          // context.dispatch('restorePigData');
        });
    },
    updatePigRunNotifications(context: any, pigRun: PigRun) {
      PigRunService.updateNotifications(pigRun)
        .then((response) => {
          console.log(response);
          context.dispatch('sendPigRunUpdateMsg', pigRun.runId);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', 'Unable to update Pig Run notifications');
          // clear the local state and resync
          // context.commit('CLOSE_PIG_RUNS');
          // context.dispatch('restorePigData');
        });
    },

    // ------------------------------------------------------------------------------------------------------------------------------------------
    // Mitch - async/await Vue.js client action dispatch experiment.
    // async executeSlowCallAction(context: any, payload: number)
    // {
    //     await PigRunService.executeSlowCall(payload)
    //     .then((response) =>
    //     {
    //         console.log('executeSlowCall action = ' + response.data);
    //     })
    //     .catch((error) =>
    //     {
    //         console.error(error);
    //     });
    // },
    //
    // async executeFastCallAction(context:any, payload: number)
    // {
    //     await PigRunService.executeFastCall(payload)
    //     .then((response) =>
    //     {
    //         console.log('executeFastCall action = ' + response.data);
    //     })
    //     .catch((error) =>
    //     {
    //         console.error(error);
    //     });
    // },

    // Mitch - broadcast commands modification.
    //
    // This action was initially written during a development iteration for broadcast commands, however,
    // it is no longer used. Leaving it here for now.
    // async addPigPathState(context: any, payload: PigPath)
    // {
    //     context.commit('ADD_PIG_PATH', payload);
    // },

    // Mitch - broadcast commands modification.
    //
    // This action was initially written during a development iteration for broadcast commands, however,
    // it is no longer used. Leaving it here for now.
    // async updatePigPathState(context: any, payload: PigPath)
    // {
    //     context.commit('DELETE_PIG_PATH', payload.pathId);
    //     context.commit('ADD_PIG_PATH', payload);
    // },

    // Mitch - broadcast commands modification.
    //
    // This action no longer appears to be used since modifying the system to support broadcast commands. Leaving this here for now,
    // however, commenting out.
    //
    // Modified the upsertPigPath action to be async.
    // Modified the PigRunService.addPigPath web api calls to be awaited.
    //
    // async upsertPigPath(context: any, payload: PigPath)
    // {
    //     if (payload.pathId === -1)
    //     {
    //         await PigRunService.addPigPath(payload)
    //         .then((response) =>
    //         {
    //             payload.pathId = response.data;
    //
    //             context.commit('ADD_PIG_PATH', payload);
    //         });
    //     }
    //     else
    //     {
    //         // delete/add same pathid to state
    //         context.commit('DELETE_PIG_PATH', payload.pathId);
    //
    //         await PigRunService.addPigPath(payload)
    //         .then((response) =>
    //         {
    //             payload.pathId = response.data;
    //
    //             context.commit('ADD_PIG_PATH', payload);
    //         });
    //     }
    //
    //     context.dispatch('showPigRunManager');
    // },

    // Mitch - broadcast commands modification.
    //
    // This action no longer appears to be used since modifying the system to support broadcast commands. Leaving this here for now,
    // however, commenting out.
    // deletePigPath(context: any, payload: number) {
    //     console.log('delete ' + payload);
    //     context.commit('DELETE_PIG_PATH', payload);
    //     PigRunService.deletePigPath(payload)
    //     .then((response) => {
    //         console.log(response);
    //     });
    // },

    launchPig(context: any, payload: PigRun) {
      context.commit('LAUNCH_PIG_RUN', payload);
    },
    cancelPigRun(context: any, payload: number) {
      context.commit('CANCEL_PIG_RUN', payload);
      PigRunService.deletePigRun(payload)
        .then((response) => {
          console.log(response);
          context.dispatch('sendPigRunUpdateMsg', payload);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },
    endPigRun(context: any, payload: PigRun) {
      context.dispatch('upsertPigRun', payload).then(() => {
        context.commit('SAVE_PIG_RUN', payload);
        context.commit('CANCEL_PIG_RUN', payload.runId);
        context.dispatch('sendPigRunUpdateMsg', payload.runId);
      });
    },
    deletePigRun(context: any, payload: number) {
      context.commit('DELETE_PIG_RUN', payload);
      PigRunService.deletePigRun(payload)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },
    // async downloadReport(context: any, pigRun: PigRun) {
    async downloadReport(context: any, payload: any) {
      const pigRun: PigRun = payload[0];
      context.commit('SET_REPORT_LOADING_STATUS', true);
      await pigRun.buildReport(payload[1]);
      // await pigRun.buildReport_V2();
      context.commit('SET_REPORT_LOADING_STATUS', false);
    },
    // async downloadReport_Summary(context: any, pigRun: PigRun) {
    async downloadReport_Summary(context: any, payload: any) {
      const pigRun: PigRun = payload[0];
      context.commit('SET_REPORT_LOADING_STATUS', true);
      await pigRun.buildReport_V2(payload[1]);
      context.commit('SET_REPORT_LOADING_STATUS', false);
    },
    async checkMissingAndPartialPassages(context: any, marker: AgmMarker) {
      console.log('checkMissingAndPartialPassages');
      context.commit('CLEAR_MISSED_PASSAGES');
      let postUpdate = false;
      const dbPassageData: { [key: number]: Passage[] } = {};
      const markerName = marker.name;
      if (context.state.activePigRuns && context.state.activePigRuns.length) {
        const pigRun = context.state.activePigRuns[0];

        // check missing
        const missedPassages = [];
        if (marker.linkedUid) {
          await DeviceService.getDeviceHistory(
            marker.linkedUid,
            'passage'
          ).then((response) => {
            // TODO filter passages by launch time should really be a backend thing
            dbPassageData[marker.linkedUid as number] = response.data.filter(
              (passage: Passage) =>
                dayjs.utc(passage.passageTimeStamp) >
                dayjs.utc(pigRun.launchTime)
            );
          });
        } else if (marker.autoLinkedUids && marker.autoLinkedUids.length) {
          for (const uid of marker.autoLinkedUids) {
            await DeviceService.getDeviceHistory(uid, 'passage').then(
              (response) => {
                dbPassageData[uid] = response.data.filter(
                  (passage: Passage) =>
                    DateCommon.sqlServerToDate(passage.passageTimeStamp)! >
                    pigRun.launchTime
                );
              }
            );
          }
        }
        const markerPassageTimestamps = pigRun.passages
          .filter(
            (passage: MarkerPassage) =>
              passage.uid && passage.markerName === markerName
          )
          .map((passage: MarkerPassage) => passage.passageTimeStamp);

        for (const [uid, dbPassageArray] of Object.entries(dbPassageData)) {
          for (const dbPassage of dbPassageArray as Passage[]) {
            // Ihab: we will accept user error if passage gets doubly linked here
            // if (dbPassage.isVerified) {
            //     // Do not allow verified passages to be added
            //     continue;
            // }
            if (
              markerPassageTimestamps.indexOf(
                dbPassage.passageTimeStamp.slice(0, -3)
              ) === -1
            ) {
              if (
                missedPassages
                  .map((p: Passage) => p.passageTimeStamp)
                  .indexOf(dbPassage.passageTimeStamp.slice(0, -3)) !== -1
              ) {
                // merge passages into 1 row
                const idx = missedPassages
                  .map((p: Passage) => p.passageTimeStamp)
                  .indexOf(dbPassage.passageTimeStamp.slice(0, -3));
                if (dbPassage.packetComplexInformation === 0) {
                  missedPassages[idx].elfId = dbPassage.passageId;
                }
                if (dbPassage.packetComplexInformation === 1) {
                  missedPassages[idx].geoId = dbPassage.passageId;
                }
                if (dbPassage.packetComplexInformation === 2) {
                  missedPassages[idx].magId = dbPassage.passageId;
                }
              } else {
                dbPassage.passageTimeStamp = dbPassage.passageTimeStamp.slice(
                  0,
                  -3
                );
                if (dbPassage.packetComplexInformation === 0) {
                  dbPassage.elfId = dbPassage.passageId;
                }
                if (dbPassage.packetComplexInformation === 1) {
                  dbPassage.geoId = dbPassage.passageId;
                }
                if (dbPassage.packetComplexInformation === 2) {
                  dbPassage.magId = dbPassage.passageId;
                }
                missedPassages.push(dbPassage);
              }
            }
          }
        }
        context.commit('SET_MISSED_PASSAGES', missedPassages);

        // check partial
        if (pigRun.passages) {
          const passages: MarkerPassage[] = pigRun.passages.filter(
            (passage: MarkerPassage) =>
              passage.uid && passage.markerName === markerName
          );
          const possibleIncompletePassages: MarkerPassage[] = [];
          for (const passage of passages) {
            if (!passage.magId || !passage.geoId || !passage.elfId) {
              possibleIncompletePassages.push(passage);
            }
          }
          const uids: number[] = [
            ...new Set(
              possibleIncompletePassages.map(
                (markerPassage: MarkerPassage) => markerPassage.uid
              ) as number[]
            ),
          ];
          for (const uid of uids) {
            if (!(uid in dbPassageData)) {
              await DeviceService.getDeviceHistory(uid, 'passage').then(
                (response) => {
                  dbPassageData[uid] = response.data.filter(
                    (passage: Passage) =>
                      dayjs.utc(passage.passageTimeStamp) >
                      dayjs.utc(pigRun.launchTime)
                  );
                }
              );
            }
          }
          for (const markerPassage of possibleIncompletePassages) {
            const dbReferencePassage = dbPassageData[
              markerPassage.uid as number
            ].find(
              (dbPassage: Passage) =>
                dbPassage.passageId === markerPassage.passageId
            );
            // check for other passage types at that timestamp
            const passageParts = dbPassageData[
              markerPassage.uid as number
            ].filter(
              (dbPassage: Passage) =>
                dbPassage.passageTimeStamp ===
                dbReferencePassage?.passageTimeStamp
            );
            for (const passagePart of passageParts) {
              if (
                !markerPassage.elfId &&
                passagePart.packetComplexInformation === 0
              ) {
                console.log('found missing elfId');
                const updatePassage = pigRun.passages.find(
                  (activeMarkerPassage: MarkerPassage) =>
                    activeMarkerPassage.passageId === markerPassage.passageId
                );
                updatePassage.elfId = passagePart.passageId;
                postUpdate = true;
              }
              if (
                !markerPassage.geoId &&
                passagePart.packetComplexInformation === 1
              ) {
                console.log('found missing geoId');
                const updatePassage = pigRun.passages.find(
                  (activeMarkerPassage: MarkerPassage) =>
                    activeMarkerPassage.passageId === markerPassage.passageId
                );
                updatePassage.geoId = passagePart.passageId;
                postUpdate = true;
              }
              if (
                !markerPassage.magId &&
                passagePart.packetComplexInformation === 2
              ) {
                console.log('found missing magId');
                const updatePassage = pigRun.passages.find(
                  (activeMarkerPassage: MarkerPassage) =>
                    activeMarkerPassage.passageId === markerPassage.passageId
                );
                updatePassage.magId = passagePart.passageId;
                postUpdate = true;
              }
            }
          }
          if (postUpdate) {
            // automatically correct partial passages if found
            context.dispatch('ignoreNextPigRunUpdate', true);
            context.dispatch('upsertPigRun', pigRun);
          }
        }
      }
    },
    removeMissedPassage(context: any, passage: Passage) {
      context.commit('REMOVE_MISSED_PASSAGE', passage);
    },
    triggerManualAlert(context: any, payload: any) {
      PigRunService.triggerManualAlert(payload.markerName, payload.runId)
        .then((response) => {
          // only expecting 200 response
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },
    // addManualMarkerPassage(context: any, markerName: string) {
    addManualMarkerPassage(context: any, markerName: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    addMarkerNote(context: any, markerName: string) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    changeMarkerDescription(context: any, markerName: string) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    // changeMarkerState(context: any, markerName: string) {
    changeMarkerState(context: any, markerName: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    assignMarkerUid(context: any, markerName: string) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    unlinkMarkerUid(context: any, markerName: string) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    calculateMarkerEta(context: any, markerName: string) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    refreshMarkerEta(context: any, markerEtas: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    // verifyMarkerPassage(context: any, markerName: string) {
    verifyMarkerPassage(context: any, markerName: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    refreshPigRun(context: any, runId: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    refreshcompPigRun(context: any, runId: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    pigRunsReady(context: any, runId: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updatePigLoc(context: any, info: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updatePigLocV2(context: any, info: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    passRunId(context: any, runId: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    passEditorRunId(context: any, runId: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updateCompRun(context: any, run: PigRun) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    pigProgressMaximizeRequest(context: any, Runid: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    pigProgressMinimizeRequest(context: any, Runid: number) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
  },
};
