
import 'leaflet/dist/leaflet.css'; // TODO: this should use cssloader
import L from 'leaflet';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet-extra-markers';
import 'leaflet-kml';
import 'leaflet-lasso';
import { mapState } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device } from '../models/Device';
import { AgmMarker } from '../models/AgmMarker';
import DeviceSearch from '@/components/DeviceSearch.vue';
import DeviceInfo from '@/components/DeviceInfo.vue';
import MarkerActions from '@/components/MarkerActions.vue';
import EditorMarkerActions from '@/components/EditorMarkerActions.vue';
import MapCommon from '../utils/MapCommon';

@Component({
  components: {
    DeviceSearch,
    DeviceInfo,
    MarkerActions,
    EditorMarkerActions,
  },
  computed: {
    ...mapState([
      'leftDrawerOpen',
      'selectedDevice',
      'selectedMarker',
      'selectedMarkerEditor',
      'devices',
      'lastDeviceStatus',
      'lastDeviceLocation',
    ]),
  },
})
export default class LeafletMap extends Vue {
  public deviceLayers: { [key: string]: L.Marker } = {};

  get markerLayers() {
    console.log('Leafletmap.vue:GET Marker Layers');
    return this.$store.state.markerLayers;
  }
  get Ihabruns() {
    // added by ihab
    console.log('Leafletmap.vue:GET Ihab Runs');
    return this.$store.state.ActiveRuns;
  }

  get activePigRuns() {
    console.log('Leafletmap.vue:GET Active Runs');
    return this.$store.state.pigRun.activePigRuns;
  }

  // subscribe to actions from vuex
  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'zoomToSelected') {
      this.zoomToSelected();
    }
    if (action.type === 'passageRecieved') {
      this.addAlert(action.payload.uid);
    }
    if (action.type === 'updateDetectionCount') {
      this.addDetectionCount(action.payload.uid, action.payload.detectionCount);
    }
    if (action.type === 'showHeartbeat') {
      this.flashHeartbeat(action.payload);
    }
    if (action.type === 'goToLatLon') {
      this.goToLatLon(action.payload);
    }
    if (action.type === 'updateDeviceIcon') {
      this.updateDeviceIcon(
        action.payload.uid,
        action.payload.isConnected,
        action.payload.isIridium
      );
    }
  });

  @Watch('leftDrawerOpen', { immediate: true, deep: true })
  public leftDrawerChanged(value: boolean, oldValue: boolean) {
    console.log('left drawer changed');
    this.refreshMap();
  }

  @Watch('selectedDevice', { immediate: false, deep: false })
  public highlightSelected(value: Device, oldValue: Device) {
    if (value.uid !== oldValue.uid) {
      if (value.uid) {
        if (!this.leftDrawerOpen || !this.showDeviceInfo) {
          // show a popup if the drawer is closed
          // Reference to DeviceInfo
          // @ts-ignore
          this.$children[1].$children[0].$el.style.display = 'block';
          this.deviceLayers[value.uid]
            .bindPopup(this.$children[1].$children[0].$el as HTMLElement, {
              minWidth: 300,
            })
            .openPopup();
        }
        if (
          value.lat &&
          value.lon &&
          value.lat !== 0 &&
          value.lon !== 0 &&
          this.deviceLayers.hasOwnProperty(value.uid)
        ) {
          const iconElement = this.deviceLayers[value.uid].getElement();
          if (iconElement) {
            iconElement.classList.add('agmIconSelected');
          }
        } else {
          this.$store.dispatch('notify', 'Device does not have a location');
        }
        this.$store.dispatch('updateLastPassage');
      }
      if (oldValue.uid && this.deviceLayers.hasOwnProperty(oldValue.uid)) {
        const iconElement = this.deviceLayers[oldValue.uid].getElement();
        if (iconElement) {
          iconElement.classList.remove('agmIconSelected');
        }
        this.deviceLayers[oldValue.uid].unbindPopup();
      }
    }
  }

  @Watch('selectedMarker', { immediate: false, deep: false })
  public highlightSelectedMarker(value: AgmMarker, oldValue: AgmMarker) {
    console.log('LeafletMap.Vue:watch highlightSelectedMarker');
    this.map.closePopup();
    if (value && value.hasOwnProperty('name')) {
      // Reference to MarkerActions
      console.log('LeafletMap.Vue:watch selected marker');
      // @ts-ignore
      this.$children[2].$children[0].$el.style.display = 'block';
      //this.markerLayers[value.name].bindPopup(this.$children[2].$children[0].$el, {minWidth: 300}).openPopup();
      // need to look for the appropriate markerlayer based on name
      this.Ihabruns.forEach((run: any) => {
        if (run.markers != null || run.markers != undefined) {
          var index = run.markers.findIndex(
            (x: AgmMarker) =>
              x.name === value.name && x.lat == value.lat && x.lon === value.lon
          );
          if (index > -1) {
            run.localmarkerLayers[value.name]
              .bindPopup(this.$children[2].$children[0].$el, { minWidth: 300 })
              .openPopup();
            this.$store.dispatch('passRunId', run.pigrunid);
            if (this.leftDrawerOpen == true) {
              this.$store.dispatch('ClearCompletedRun');
            }
            return;
          }
        }
      });
    }
    if (this.leftDrawerOpen == true) {
      this.$store.dispatch('ClearCompletedRun');
    }
  }

  @Watch('selectedMarkerEditor', { immediate: false, deep: false })
  public highlightSelectedMarkerEditor(value: AgmMarker, oldValue: AgmMarker) {
    console.log('LeafletMap.Vue:watch highlightSelectedMarkerEditor');
    this.map.closePopup();
    if (value && value.hasOwnProperty('name')) {
      // Reference to MarkerActions
      console.log('LeafletMap.Vue:watch selected marker');
      // @ts-ignore
      //this.$children[3].$children[0].$el.style.display = 'block';
      //this.markerLayers[value.name].bindPopup(this.$children[2].$children[0].$el, {minWidth: 300}).openPopup();
      // need to look for the appropriate markerlayer based on name

      this.Ihabruns.forEach((run: any) => {
        if (run.markers != null || run.markers != undefined) {
          var index = run.markers.findIndex(
            (x: AgmMarker) =>
              x.name === value.name && x.lat == value.lat && x.lon === value.lon
          );
          if (index > -1) {
            run.localmarkerLayers[value.name]
              .bindPopup(this.$children[3].$children[0].$el, { minWidth: 300 })
              .openPopup();
            this.$store.dispatch('passEditorRunId', run.pigrunid);
            return;
          }
        }
      });
    }
  }

  // sleep.
  //
  // A work around method implemented to address watcher execution occurring prior to reactivity. This is only called by
  // the devicesUpdated watcher callback at this point.
  public sleep(milliseconds: any) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  // devicesUpdated.
  //
  // This method is used to display agm device icons on the map. This method watches the vuex state store devices member for
  // a change. When a change is detected the callback is executed. Note that watcher callbacks are executed prior to
  // vue reactivity.
  //
  // To work around the callback execution occurring prior to reactivity, a one second sleep was implemented for authenticated
  // users having viewer role membership.
  //
  // A better solution to explore in the future relates to callback flush timing (i.e., flush: 'post') available in a more recent version of Vue.
  @Watch('devices', { immediate: false, deep: false })
  public async devicesUpdated(value: Device[], oldValue: Device[]) {
    // If the authenticated user has viewer role membership.
    if (this.$store.state.user.role == 'viewer') {
      await this.sleep(1000);
    }

    if (oldValue.length > 0) {
      this.showDevices(false);
    } else {
      this.showDevices(true);
    }
  }

  // updateDeviceStatus.
  //
  // The implementation was modified to include the icons for iridium.
  @Watch('lastDeviceStatus', { immediate: false, deep: true })
  public updateDeviceStatus(value: object, oldValue: any) {
    for (const [uid, props] of Object.entries(value)) {
      if (this.deviceLayers.hasOwnProperty(uid)) {
        // ONLY if this is on the map

        // Remove all the CSS classes from the icon.
        const iconElement = this.deviceLayers[uid].getElement();
        if (iconElement) {
          iconElement.classList.remove('agmIconOffline');
          iconElement.classList.remove('agmIconOnline');
          iconElement.classList.remove('agmIconOnlineIridium');
          iconElement.classList.remove('agmIconOfflineIridium');
        }

        // Look up the device.
        let deviceIndex = this.$store.state.devices.findIndex(
          (element: Device) => element.uid == parseInt(uid, 10)
        );

        if (deviceIndex != -1) {
          let device: Device = this.$store.state.devices[deviceIndex];

          // console.log('LeafletMap. device.isIridium = ' + device.isIridium);
          // console.log('LeafletMap. device.isConnected = ' + device.isConnected);

          // Add the correct CSS class to the icon.
          const iconElement = this.deviceLayers[uid].getElement();
          if (!iconElement) {
            return;
          }

          if (device.isIridium) {
            if (device.isConnected) {
              iconElement.classList.add('agmIconOnlineIridium');
            } else {
              iconElement.classList.add('agmIconOfflineIridium');
            }
          } else {
            if (device.isConnected) {
              iconElement.classList.add('agmIconOnline');
            } else {
              iconElement.classList.add('agmIconOffline');
            }
          }
        }
      }
    }
  }

  @Watch('lastDeviceLocation', { immediate: false, deep: true })
  public updateDeviceLocation(value: object, oldValue: any) {
    console.log('LeafletMap: updateDeviceLocation()');
    for (const [uid, props] of Object.entries(value)) {
      // console.log(this.deviceLayers[uid]);
      if (this.deviceLayers.hasOwnProperty(uid)) {
        // ONLY if this is on the map
        this.deviceLayers[uid].setLatLng(L.latLng(props.lat, props.lon));
      } else {
        // This is not a new device, but it previously did not have a location
        this.addDeviceToMap(
          parseInt(uid, 10),
          props.lat,
          props.lon,
          true,
          false
        );
      }
    }
  }

  public addDeviceToMap(
    uid: number,
    lat: number | undefined,
    lon: number | undefined,
    isConnected: boolean | undefined,
    isIridium: boolean | undefined
  ) {
    let myIcon;
    if (isIridium) {
      // myIcon = L.divIcon({
      //     className: isConnected ? 'agmIconOnline iridium' : 'agmIconOffline iridium',
      //     iconSize: [28, 28],
      //     html: '',
      // });
      myIcon = L.divIcon({
        className: isConnected
          ? 'agmIconOnlineIridium'
          : 'agmIconOfflineIridium',
        iconSize: [32, 32],
        html: '',
      });
    } else {
      myIcon = L.divIcon({
        className: isConnected ? 'agmIconOnline' : 'agmIconOffline',
        iconSize: [32, 32],
        html: '',
      });
    }
    if (lat && lon) {
      if (!this.deviceLayers.hasOwnProperty(uid)) {
        // only add new
        const layer = L.marker([lat, lon], { icon: myIcon })
          .addTo(this.getMap())
          .on('click', (evt) => {
            this.$store.dispatch('setSelectedDevice', uid);
          });
        this.deviceLayers[uid] = layer;
        // @ts-ignore
        this.$store.dispatch('setLeafletLayer', {
          uid,
          leafletId: (layer as any)._leaflet_id,
        });
      } else {
        // if layer exists, move marker
        this.deviceLayers[uid].setLatLng([lat, lon]);
        this.deviceLayers[uid].setIcon(myIcon);
      }
    }
  }

  get map() {
    return this.$store.state.map;
  }

  get showDeviceInfo(): boolean {
    return this.$store.state.showDeviceInfo;
  }

  get selectedDevice(): Device {
    return this.$store.state.selectedDevice;
  }

  get leftDrawerOpen(): boolean {
    return this.$store.state.leftDrawerOpen;
  }

  public addAlert(alertUid: number) {
    for (const uid of Object.keys(this.deviceLayers)) {
      this.removeAlert(parseInt(uid, 10)); // remove other alerts, and prevent duplicates
      if (alertUid === parseInt(uid, 10)) {
        if (this.deviceLayers.hasOwnProperty(alertUid)) {
          const template = document.createElement('template');
          template.innerHTML = '<div class="passageAlarm"></div>'.trim();
          const iconElement = this.deviceLayers[uid].getElement();
          if (!iconElement) {
            return;
          }
          if (this.activePigRuns.length) {
            // the pig run is going to overwrite this icon on passages so its getting a small delay
            setTimeout(() => {
              iconElement.appendChild(template.content.firstChild as Node);
            }, 1000);
          } else {
            iconElement.appendChild(template.content.firstChild as Node);
          }
        }
      }
    }
  }

  public removeAlert(uid: number) {
    if (this.deviceLayers.hasOwnProperty(uid)) {
      const iconElement = this.deviceLayers[uid].getElement();
      if (iconElement) {
        iconElement.childNodes.forEach(
          (currentNode: any, currentIndex: any, listObj: any) => {
            if (currentNode.className === 'passageAlarm') {
              iconElement.removeChild(currentNode);
            }
          },
          this
        );
      }
    }
  }

  public addDetectionCount(dcUid: number, detectionCount: number) {
    // TODO for now this is the same behavior as alert
    // only show DC on the last passage
    for (const uid of Object.keys(this.deviceLayers)) {
      this.removeDetectionCount(parseInt(uid, 10)); // remove others, and prevent duplicates
      if (dcUid === parseInt(uid, 10)) {
        if (this.deviceLayers.hasOwnProperty(uid)) {
          const template = document.createElement('template');
          let styleModifier = '';
          if (detectionCount.toString().length === 3) {
            styleModifier = 'style="width: 20px"';
          } else if (detectionCount.toString().length === 4) {
            styleModifier = 'style="width: 26px"';
          }
          template.innerHTML =
            '<div class="detectCount"' +
            styleModifier +
            '>' +
            detectionCount +
            '</div>'.trim();

          const iconElement = this.deviceLayers[uid].getElement();
          if (!iconElement) {
            return;
          }

          if (this.activePigRuns.length) {
            // the pig run is going to overwrite this icon on passages so its getting a small delay
            setTimeout(() => {
              iconElement.appendChild(template.content.firstChild as Node);
            }, 1000);
          } else {
            iconElement.appendChild(template.content.firstChild as Node);
          }
        }
      }
    }
  }

  public removeDetectionCount(uid: number) {
    if (this.deviceLayers.hasOwnProperty(uid)) {
      const iconElement = this.deviceLayers[uid].getElement();
      if (iconElement) {
        iconElement.childNodes.forEach(
          (currentNode: any, currentIndex: any, listObj: any) => {
            if (currentNode.className === 'detectCount') {
              iconElement.removeChild(currentNode);
            }
          },
          this
        );
      }
    }
  }

  public flashHeartbeat(uid: number) {
    if (this.deviceLayers.hasOwnProperty(uid)) {
      const template = document.createElement('template');
      template.innerHTML = '<div class="heart"></div>'.trim();
      const iconElement = this.deviceLayers[uid].getElement();
      if (iconElement) {
        iconElement.appendChild(template.content.firstChild as Node);
        setTimeout(
          () => {
            iconElement.childNodes.forEach(
              (currentNode: any, currentIndex: any, listObj: any) => {
                if (currentNode.className === 'heart') {
                  iconElement.removeChild(currentNode);
                }
              },
              this
            );
          },
          2000,
          this
        );
      }
    }
  }

  public zoomToSelected() {
    if (this.selectedDevice.lat && this.selectedDevice.lon) {
      const pt: L.LatLng = L.latLng(
        this.selectedDevice.lat,
        this.selectedDevice.lon
      );
      this.getMap().setView(pt, 15);
    } else {
      // already notified when selected
      // this.$store.dispatch('notify', 'Device does not have a location');
    }
  }

  public updateDeviceIcon(
    uid: number,
    isConnected: boolean,
    isIridium: boolean
  ) {
    console.log('LeafletMap: updateDeviceIcon');
    if (this.deviceLayers.hasOwnProperty(uid)) {
      if (isIridium) {
        if (isConnected) {
          this.deviceLayers[uid].setIcon(MapCommon.agmIconOnlineIridium);
        } else {
          this.deviceLayers[uid].setIcon(MapCommon.agmIconOfflineIridium);
        }
      } else {
        if (isConnected) {
          this.deviceLayers[uid].setIcon(MapCommon.agmIconOnline);
        } else {
          this.deviceLayers[uid].setIcon(MapCommon.agmIconOffline);
        }
      }
    }
  }

  public createMap() {
    const defaultCenter: L.LatLng = L.latLng(44.9778, -93.265044);
    const defaultZoom = 4;

    const topoLayer = L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.jpg',
      {
        attribution:
          '<span>Esri, HERE, Garmin, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), (c) OpenStreetMap contributors, and the GIS User Community</span>',
        id: 'topo',
      }
    );

    const satelliteLayer = L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg',
      {
        attribution:
          '<span>Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community</span>',
        id: 'satelite',
      }
    );

    // TODO: Need to find a label layer that isn't esri vector basemap. Then remove opacity
    // A simpler basemap might have better labels?
    const labelLayer = L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.jpg',
      {
        attribution:
          '<span>Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community</span>',
        opacity: 0.4,
      }
    );

    //test//test//test
    const googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    );

    const googleHybrid = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    );

    const googleSat = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    );

    const googleTerrain = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    );

    const hybridLayer = L.layerGroup([satelliteLayer, labelLayer]);

    const testWeather = L.tileLayer(
      'https://maps.aerisapi.com/t1CFKrqOi2EUtihcdD6r7_rxIWdnGxfXJxAx38ESqZ4twIp9aHONrRKIsa5Hll/fradar/{z}/{x}/{y}/current.png',
      {
        maxZoom: 20,
        opacity: 0.4,
      }
    );
    const WeatherRadarHybrid = L.layerGroup([googleHybrid, testWeather]);

    const lMap: L.Map = L.map('map', {
      layers: [googleHybrid],
      maxZoom: 20,
      minZoom: 3,
      maxBounds: [
        [90, -180],
        [-90, 180],
      ],
    }).setView(defaultCenter, defaultZoom);

    this.$store.state.map = lMap;

    //topoLayer.addTo(lMap);
    googleStreets.addTo(lMap);

    /* On action methods that can be used to detect change in layers
        googleStreets.on("load",function() { console.log('load') });
        googleStreets.on("tileunload",function() { console.log('unload') });  
        googleStreets.on("loading",function() {  console.log('loading') });
        googleStreets.on("add",function() {  console.log('add') });
        googleStreets.on("remove",function() {  console.log('remove') });*/

    const baseMaps = {
      Basic: topoLayer,
      //Hybrid: hybridLayer,
      Streets: googleStreets,
      Satellite: satelliteLayer,
      Default: googleHybrid,
      //WeatherRadar: WeatherRadarHybrid, // Free 30 days
      //GoogleSat:googleSat,
      //GoogleTerrain:googleTerrain
    };

    // layerControl is the control for the basemap
    const layerControl = new L.Control.Layers(
      baseMaps,
      {},
      { position: 'topleft' }
    ) as L.Control.Layers;
    //secondary layer control is used for pig runs
    const secondaryLayerControl = new L.Control.Layers(
      {},
      {},
      { collapsed: false, position: 'topleft' }
    ) as L.Control.Layers;
    // this initial layer is an empty layer for showing NO pig runs
    secondaryLayerControl.addBaseLayer(L.layerGroup([]), 'Show No Active Runs');

    //this.$store.dispatch('setLayerControl', layerControl);
    this.$store.dispatch('setLayerControl', secondaryLayerControl); // only the control for pig runs is put in the store
    this.createHomeControl();
    layerControl.addTo(lMap);
    secondaryLayerControl.addTo(lMap);
  }

  public zoomHome() {
    const latLngs: L.LatLng[] = [];
    for (const uid of Object.keys(this.deviceLayers)) {
      latLngs.push(this.deviceLayers[uid].getLatLng());
    }
    if (latLngs.length > 0) {
      this.getMap().fitBounds(L.latLngBounds(latLngs), { maxZoom: 19 });
    } else {
      this.$store.dispatch('notify', 'No device locations found');
    }
  }

  public createHomeControl() {
    const HomeControl = L.Control.extend({
      onAdd: (map: L.Map) => {
        const container = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leafletControlHome'
        );
        container.style.backgroundImage = 'url(home-24px.svg)';
        container.style.backgroundColor = 'white';
        container.style.backgroundPosition = 'center';
        container.style.width = '26px';
        container.style.height = '26px';
        container.onclick = () => {
          this.zoomHome();
        };
        return container;
      },

      onRemove: (map: L.Map) => {
        // Nothing to do here
      },
    });

    const hc = new (HomeControl as any)({ position: 'topleft' }) as L.Control;
    hc.addTo(this.getMap() as L.Map);
  }

  // showDevices
  //
  // The behaviour of this function was modified such that the only devices added to the map for an authenticated user
  // with viewer role membership are those participating in active pig runs for which the user has a notification subscription.
  public showDevices(isZoom: boolean) {
    // For each device.
    this.$store.state.devices.forEach((device: Device) => {
      // If the authenticated user is in the viewer role.
      if (this.$store.state.user.role == 'viewer') {
        // console.log('LeafletMap.vue. user.role = ' + this.$store.state.user.role);
        // console.log('LeafletMap.vue. activePigRunUidArrayWithNotification.length = ' + this.$store.state.activePigRunUidArrayWithNotification.length);

        // If there are any uid values in the activePigRunUidArrayWithNotification.
        if (
          this.$store.state.activePigRunUidArrayWithNotification != undefined &&
          this.$store.state.activePigRunUidArrayWithNotification.length > 0
        ) {
          // If the activePigRunUidArrayWithNotification state member contains the current uid value being iterated, add it to the map.
          if (
            this.$store.state.activePigRunUidArrayWithNotification.findIndex(
              (element: number) => element == device.uid
            ) != -1
          ) {
            this.addDeviceToMap(
              device.uid,
              device.lat,
              device.lon,
              device.isConnected,
              device.isIridium
            );
          }
        }
      } else {
        this.addDeviceToMap(
          device.uid,
          device.lat,
          device.lon,
          device.isConnected,
          device.isIridium
        );
      }
    });

    if (isZoom) {
      if (this.$store.state.devices.length > 0) {
        this.zoomHome();
      } else {
        this.$store.dispatch('notify', 'No devices found');
      }
    }

    /* Mitch - previous implementation (start)

        // TODO switch this to computed getter
        this.$store.state.devices.forEach( (device: Device) => {
            this.addDeviceToMap(device.uid, device.lat, device.lon, device.isConnected, device.isIridium);
        });

        if (isZoom) {
            if (this.$store.state.devices.length > 0) {
                this.zoomHome();
            } else {
                this.$store.dispatch('notify', 'No devices found');
            }
        }

        Mitch - previous implementation (end)
        */
  }

  public refreshMap() {
    if (this.getMap()) {
      this.getMap().invalidateSize();
    }
  }

  public goToLatLon(queryParams: any) {
    const latLng = new L.LatLng(queryParams.lat, queryParams.lon);
    this.map.setView(latLng, 16);
  }

  public mounted() {
    console.log('Map: mounted()');
    this.createMap();
    this.$store.dispatch('initSignalR');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  private getMap() {
    return this.$store.state.map;
  }
}
