// DownloadRecordedRawDataRequest.
//
// Class representing a download recorded raw data request.
export class DownloadRecordedRawDataRequest {
  // End date.
  public endDate: Date;

  // Start date.
  public startDate: Date;

  // Uid.
  public uid: number;

  // Constructor.
  public constructor(uid: number, startDate: Date, endDate: Date) {
    this.uid = uid;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
