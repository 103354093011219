import L from 'leaflet';

export default class MapCommon {
  // classes are still defined in LeafletMap component CSS
  public static agmIconOnline = L.divIcon({
    className: 'agmIconOnline',
    iconSize: [32, 32],
  });

  public static agmIconOffline = L.divIcon({
    className: 'agmIconOffline',
    iconSize: [32, 32],
  });

  public static agmIconOnlineIridium = L.divIcon({
    className: 'agmIconOnlineIridium',
    iconSize: [32, 32],
  });

  public static agmIconOfflineIridium = L.divIcon({
    className: 'agmIconOfflineIridium',
    iconSize: [32, 32],
  });

  public static unassignedIcon = L.divIcon({
    className: 'agmIconOfflinePigRun',
    iconSize: [32, 32],
  });

  public static assignedOnlineIcon = L.divIcon({
    className: 'agmIconAssignedPigRun',
    iconSize: [32, 32],
  });

  public static assignedOfflineIcon = L.divIcon({
    className: 'agmIconOfflinePigRun',
    iconSize: [32, 32],
  });

  public static assignedIridiumOnlineIcon = L.divIcon({
    className: 'agmIconAssignedPigRunIridium',
    iconSize: [32, 32],
  });

  public static assignedIridiumOfflineIcon = L.divIcon({
    className: 'agmIconOfflinePigRunIridium',
    iconSize: [32, 32],
  });
}
