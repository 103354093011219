// added by Ihab//
export class GeoCommand {
  public static Low = 'Low';
  public static Medium = 'Medium';
  public static High = 'High';
  public static VeryHigh = 'Very High';

  public static getModes() {
    return [
      this.Low, // 0
      this.Medium, // 1
      this.High, // 2
      this.VeryHigh, // 3
    ];
  }
  public static byIdx(i: number) {
    return [
      this.Low, // 0
      this.Medium, // 1
      this.High, // 2
      this.VeryHigh, // 3
    ][i];
  }
  public static toString(s: string) {
    const idx = [
      'Low', // 0
      'Medium', // 1
      'High', // 2
      'Very High', // 3
    ].indexOf(s);
    if (idx !== -1) {
      return this.byIdx(idx);
    } else {
      return '';
    }
  }
}
