
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ChangePassword extends Vue {
  public show = false;
  public newPassword = '';
  public newPasswordVerification = '';

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'changePassword') {
      this.showDialog();
    }
  });

  public showDialog() {
    this.show = true;
    this.newPassword = '';
    this.newPasswordVerification = '';
  }

  public isValidPassword(val: string) {
    const passwordPattern =
      /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
    return (
      passwordPattern.test(val) ||
      'Invalid password - use at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)'
    );
  }

  public doesPasswordMatch(val: string) {
    if (val !== this.newPassword) {
      return 'Password does not match';
    }
    return true;
  }

  public onPasswordChange() {
    this.$store.dispatch('changeUserPassword', this.newPassword);
  }

  public mounted() {
    console.log('ChangePassword: mounted()');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
