// UpdatePassageTimeStampRequest.
//
// Class representing a request to update a passage time stamp.
export class UpdatePassageTimeStampRequest {
  // PassageId.
  //
  // Note: This input parameter will correspond to a Passage.Aid value and could represent either an elf, geo, or mag record (it does not matter which).
  public passageId: number;

  // Update passage time stamp.
  public passageTimeStamp: Date;

  // Constructor.
  public constructor(passageId: number, passageTimeStamp: Date) {
    this.passageId = passageId;
    this.passageTimeStamp = passageTimeStamp;
  }
}
