import axios from 'axios';
import store from '../store';
import { UserMgmt } from '../models/UserMgmt';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let token = '';

export default {
  getUsers() {
    token = store.getters.token;
    return apiClient.get('/user', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  postUser(user: UserMgmt) {
    token = store.getters.token;
    return apiClient.post('/user', user, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteUser(userId: string) {
    token = store.getters.token;
    return apiClient.delete('/user/' + userId, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  setPassword(user: UserMgmt) {
    token = store.getters.token;
    return apiClient.post('/user/setpassword', user, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
