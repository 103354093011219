export class PassageState {
  public static DetectionAndNoGeo = 'Detection & No GEO';
  public static DetectionAndGeo = 'Detection & GEO';
  public static MagElfAndNoGeo = 'MAG/ELF & No GEO';
  public static MagElfAndGeo = 'MAG/ELF & GEO';

  public static byIdx(i: number) {
    return [
      this.DetectionAndNoGeo, // 0
      this.DetectionAndGeo, // 1
      this.MagElfAndNoGeo, // 2
      this.MagElfAndGeo, // 3
    ][i];
  }
}
