import { PowerMode } from './PowerMode';
import { ConnectionSchedule } from './ConnectionSchedule';
import { StreamMode } from './StreamMode';
import { RecordMode } from './RecordMode';

export interface IDevice {
  uid: number;
  lat?: number;
  lon?: number;
  lastHeartbeat?: Date;
  lastPassage?: Date;
  lastDetectionCount?: number;
  lastPowerMode?: PowerMode;
  lastStreamMode?: StreamMode;
  lastSignalStrength?: number;
  lastBattery?: number;
  lastPassageId?: number;
  lastFrequency0Setting?: number;
  lastFrequency1Setting?: number;
  lastFrequency2Setting?: number;
  lastFrequency3Setting?: number;
  lastSensitivity0?: number;
  lastSensitivity1?: number;
  lastSensitivity2?: number;
  lastSensitivity3?: number;
  lastEnable0?: boolean;
  lastEnable1?: boolean;
  lastEnable2?: boolean;
  lastEnable3?: boolean;
  lastRecordMode?: RecordMode;
  schedule?: ConnectionSchedule;
  bluetooth?: number;
  isConnected?: boolean;
  isIridium?: boolean;
  psgState?: number;
  psgSize?: number;
  imei?: string;
  isAutoDownload?: boolean;
  lastGeoMode?: number;
}

export class Device implements IDevice {
  constructor(
    public uid: number,
    public lat?: number,
    public lon?: number,
    public lastHeartbeat?: Date,
    public lastPassage?: Date,
    public lastDetectionCount?: number,
    public lastPowerMode?: PowerMode,
    public lastStreamMode?: StreamMode,
    public lastSignalStrength?: number,
    public lastBattery?: number,
    public lastPassageId?: number,
    public lastFrequency0Setting?: number,
    public lastFrequency1Setting?: number,
    public lastFrequency2Setting?: number,
    public lastFrequency3Setting?: number,
    public lastSensitivity0?: number,
    public lastSensitivity1?: number,
    public lastSensitivity2?: number,
    public lastSensitivity3?: number,
    public lastEnable0?: boolean,
    public lastEnable1?: boolean,
    public lastEnable2?: boolean,
    public lastEnable3?: boolean,
    public lastRecordMode?: RecordMode,
    public schedule?: ConnectionSchedule,
    public bluetooth?: number,
    public isConnected?: boolean,
    public isIridium?: boolean,
    public psgState?: number,
    public psgSize?: number,
    public imei?: string,
    public isAutoDownload?: boolean,
    public lastExpectedWakeupTime?: Date,
    public lastShutdownTime?: Date,
    public lastGeoMode?: number,
    public firmwareVersion?: number,
    public groupId?: number
  ) {
    if (this.lastSignalStrength) {
      this.lastSignalStrength = -Math.abs(this.lastSignalStrength);
    }
  }
}
