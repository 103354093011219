
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateCommon from '@/utils/DateCommon';
import { Device } from '@/models/Device';
import { BroadcastCommandsRequest } from '@/models/BroadcastCommands/BroadcastCommandsRequest';
import PigRunService from '@/services/PigRunService';

@Component
export default class BroadcastCommands extends Vue {
  // Properties for the commands corresponding to the checkbox and date controls.
  public broadcastCommandPowerMode = false;
  public broadcastCommandPowerModeLowPower = false;
  public broadcastCommandFrequencySettingsAllDetectorsOff = false;
  public broadcastCommandFrequencySettingsMagOnly = false;
  public broadcastCommandFrequencySettingsElfMag = false;
  public broadcastCommandFrequencySettingsElfOnly = false;
  public broadcastCommandFormatDisk = false;

  public broadcastCommandRequestForDownload = false;
  public broadcastCommandRequestForDownloadStartDate = '';
  public broadcastCommandRequestForDownloadStartDateAsDate: Date | undefined;

  public broadcastCommandRequestForDownloadEndDate = '';
  public broadcastCommandRequestForDownloadEndDateAsDate: Date | undefined;

  public broadcastCommandRequestForDownloadDisplayDateControls = false;

  // Property for the selected broadcast commands.
  public selectedBroadcastCommandArray: Array<string> = [];

  // Property for the selected uid value checkboxes.
  public selectedDevices: number[] = [];

  // Property for showing the select commands dialog.
  public showSelectCommandsDialog = false;

  // Property for showing the select devices dialog.
  public showSelectDevicesDialog = false;

  // Validation related properties.
  public showValidationMessageDialog = false;
  public validationDialogTitle = '';
  public validationDialogMessage = '';

  // Computed getter for all available device options.
  get allDeviceOptions() {
    let allDeviceOptions: any = [];

    // Iterate each device.
    this.$store.state.devices.forEach((device: Device) => {
      allDeviceOptions.push({
        label: 'AGM UID ' + device.uid,
        value: device.uid,
      });
    });

    return allDeviceOptions;
  }

  // Lifecycle hook.
  public mounted() {
    console.log('BroadcastCommands.vue. mounted().');
  }

  // Broadcast command request for download checkbox click handler.
  public onBroadcastCommandRequestForDownloadCheckboxClick() {
    console.log(
      'BroadcastCommands.vue. onBroadcastCommandRequestForDownloadCheckboxClick.'
    );

    if (this.broadcastCommandRequestForDownload) {
      this.broadcastCommandRequestForDownloadDisplayDateControls = true;
    } else {
      this.broadcastCommandRequestForDownloadDisplayDateControls = false;
    }
  }

  // Broadcast commands component close click handler.
  public onBroadcastCommandsCloseClick() {
    console.log('BroadcastCommands.vue. onBroadcastCommandsCloseClick.');

    this.$store.dispatch('showPigRunManager');
  }

  // Select commands button click handler.
  public onSelectCommandsDialogSaveButtonClick() {
    console.log(
      'BroadcastCommands.vue. onSelectCommandsDialogSaveButtonClick.'
    );

    this.showSelectCommandsDialog = false;
  }

  // Select devices button click handler.
  public onSelectDevicesButtonClick() {
    console.log('BroadcastCommands.vue. onSelectDevicesButtonClick.');

    // Show the select devices dialog.
    this.showSelectDevicesDialog = true;
  }

  // Select commands button click handler.
  public onSelectCommandsButtonClick() {
    console.log('BroadcastCommands.vue. onSelectCommandsButtonClick.');

    // Show the select commands dialog.
    this.showSelectCommandsDialog = true;
  }

  // Select devices dialog save button click handler.
  public onSelectDevicesDialogSaveButtonClick() {
    console.log('BroadcastCommands.vue. onSelectDevicesDialogSaveButtonClick.');

    for (let i = 0; i < this.selectedDevices.length; i++) {
      console.log(this.selectedDevices[i]);
    }

    this.showSelectDevicesDialog = false;
  }

  // Send commands now button click handler.
  public async onSendCommandsNowButtonClick() {
    console.log('BroadcastCommands.vue. onSendCommandsNowButtonClick.');

    // Validation: At least one device must be selected.
    if (this.selectedDevices == undefined || this.selectedDevices.length == 0) {
      this.validationDialogMessage = 'At least one AGM must be selected.';
      this.validationDialogTitle = 'Validation Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: Each of the four frequency settings broadcast commands are mutually exclusive. If more than one is selected,
    //             display a validation message.
    let selectedFrequencySettingsCommandCount = 0;

    if (this.broadcastCommandFrequencySettingsMagOnly) {
      selectedFrequencySettingsCommandCount++;
    }

    if (this.broadcastCommandFrequencySettingsElfMag) {
      selectedFrequencySettingsCommandCount++;
    }

    if (this.broadcastCommandFrequencySettingsElfOnly) {
      selectedFrequencySettingsCommandCount++;
    }

    if (this.broadcastCommandFrequencySettingsAllDetectorsOff) {
      selectedFrequencySettingsCommandCount++;
    }

    if (selectedFrequencySettingsCommandCount > 1) {
      this.validationDialogMessage =
        'Only one settings broadcast command may be selected at a time.';
      this.validationDialogTitle = 'Validation Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: The power mode options (active and low power) are mutually exclusive. If both are selected, display a validation message.
    if (
      this.broadcastCommandPowerMode &&
      this.broadcastCommandPowerModeLowPower
    ) {
      this.validationDialogMessage =
        'The power mode (active) and power mode (low power) commands are mutually exclusive, only one may be selected at a time.';
      this.validationDialogTitle = 'Validation Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: The format disk and the download between dates commands are mutually exclusive. If both are selected,
    //             display a validation message.
    if (
      this.broadcastCommandFormatDisk &&
      this.broadcastCommandRequestForDownload
    ) {
      this.validationDialogMessage =
        'The format disk and download between dates commands are mutually exclusive, only one may be selected at a time.';
      this.validationDialogTitle = 'Validation Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Remove all the elements from the selectedBroadcastCommandArray object (if any).
    this.selectedBroadcastCommandArray.length = 0;

    // Interrogate the state of each broadcast command checkbox and build the selectedBroadcastCommandArray object.
    //
    // Power mode (active).
    if (this.broadcastCommandPowerMode) {
      this.selectedBroadcastCommandArray.push('PM');
    }

    // Power mode (low power).
    if (this.broadcastCommandPowerModeLowPower) {
      this.selectedBroadcastCommandArray.push('PMLP');
    }

    // Settings - mag only.
    if (this.broadcastCommandFrequencySettingsMagOnly) {
      this.selectedBroadcastCommandArray.push('FS1');
    }

    // Settings - mag + 22 Hz.
    if (this.broadcastCommandFrequencySettingsElfMag) {
      this.selectedBroadcastCommandArray.push('FS2');
    }

    // Settings - 22 Hz only.
    if (this.broadcastCommandFrequencySettingsElfOnly) {
      this.selectedBroadcastCommandArray.push('FS3');
    }

    // Settings - all detectors off.
    if (this.broadcastCommandFrequencySettingsAllDetectorsOff) {
      this.selectedBroadcastCommandArray.push('FS4');
    }

    // Format disk.
    if (this.broadcastCommandFormatDisk) {
      this.selectedBroadcastCommandArray.push('SD');
    }

    // Download between dates.
    if (this.broadcastCommandRequestForDownload) {
      // Validation: If the specified start date is not valid, display a validation error.
      if (
        !DateCommon.IsDateAsStringValid(
          this.broadcastCommandRequestForDownloadStartDate
        )
      ) {
        this.validationDialogMessage =
          'If the download between dates command is selected, the associated start date must be specified.';
        this.validationDialogTitle = 'Validation Message';
        this.showValidationMessageDialog = true;

        return;
      }

      // Validation: If the specified end date is not valid, display a validation error.
      if (
        !DateCommon.IsDateAsStringValid(
          this.broadcastCommandRequestForDownloadEndDate
        )
      ) {
        this.validationDialogMessage =
          'If the download between dates command is selected, the associated end date must be specified.';
        this.validationDialogTitle = 'Validation Message';
        this.showValidationMessageDialog = true;

        return;
      }

      // Convert the specified valid start date to a native Date object.
      this.broadcastCommandRequestForDownloadStartDateAsDate =
        DateCommon.ConvertDateAsStringToDate(
          this.broadcastCommandRequestForDownloadStartDate
        );

      // Convert the specified valid end date to a native Date object.
      this.broadcastCommandRequestForDownloadEndDateAsDate =
        DateCommon.ConvertDateAsStringToDate(
          this.broadcastCommandRequestForDownloadEndDate
        );

      // Validation: If the start date is not before the end date, display a validation error.
      if (
        this.broadcastCommandRequestForDownloadStartDateAsDate.getTime() >=
        this.broadcastCommandRequestForDownloadEndDateAsDate.getTime()
      ) {
        this.validationDialogMessage =
          'If the download between dates command is selected, the specified start date must be before the specified end date.';
        this.validationDialogTitle = 'Validation Message';
        this.showValidationMessageDialog = true;

        return;
      }

      this.selectedBroadcastCommandArray.push('RFD');
    }

    // Validation: If there are no broadcast commands selected, display a validation error.
    if (
      this.selectedBroadcastCommandArray == undefined ||
      this.selectedBroadcastCommandArray.length == 0
    ) {
      this.validationDialogMessage =
        'At least one broadcast command must be selected.';
      this.validationDialogTitle = 'Validation Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Construct a BroadcastCommandsRequest object.
    let broadcastCommandsRequest: BroadcastCommandsRequest | undefined;

    // If the request for download command has been selected.
    if (this.broadcastCommandRequestForDownload) {
      broadcastCommandsRequest = new BroadcastCommandsRequest(
        this.selectedDevices,
        this.selectedBroadcastCommandArray,
        this.broadcastCommandRequestForDownloadStartDateAsDate,
        this.broadcastCommandRequestForDownloadEndDateAsDate
      );
    } else {
      broadcastCommandsRequest = new BroadcastCommandsRequest(
        this.selectedDevices,
        this.selectedBroadcastCommandArray,
        undefined,
        undefined
      );
    }

    // Call the web api service method.
    await PigRunService.createBroadcastCommands(broadcastCommandsRequest)
      .then((response) => {
        console.log(response);

        // Display a successful confirmation message.
        this.validationDialogMessage = 'Broadcast commands successfully sent.';
        this.validationDialogTitle = 'Information Message';
        this.showValidationMessageDialog = true;
      })
      .catch((exception) => {
        console.error(exception);

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display an unsuccessful message.
          this.validationDialogMessage =
            'Broadcast commands not successfully sent. LogId = [' +
            exception.response.data +
            '].';
          this.validationDialogTitle = 'Error Message';
          this.showValidationMessageDialog = true;
        }

        return;
      });
  }

  // Computed property getter for the display of the broadcast commands component.
  get showBroadcastCommands() {
    return this.$store.state.showBroadcastCommands;
  }
}
