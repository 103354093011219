import * as signalR from '@aspnet/signalr';
import store from '../store';

let signalRConnection: signalR.HubConnection;
async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
async function connect(conn: any) {
  conn.start().catch((e: any) => {
    // these errors are NOT timeouts
    console.error(e);
    setTimeout(() => {
      console.log('Reconnecting Message Socket');
      connect(conn);
    }, 5000);
    // sleep(5000); // progressivley increase this?
    // console.log('Reconnecting Socket');
    // connect(conn);
  });
}

// reconnect on close/timeout

export default {
  init() {
    const token: string = store.getters.token;
    signalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_API_ROOT + '/agmhub', {
        accessTokenFactory: () => token,
      })
      // .configureLogging(signalR.LogLevel.Critical) // TODO uncomment this in prod so all reconnects aren't logged
      .build();
    signalRConnection.onclose((err: any) => {
      connect(signalRConnection);
    });
    connect(signalRConnection);
  },
  getConnection() {
    return signalRConnection;
  },
};
