
import { colors } from 'quasar';
import { Component, Vue } from 'vue-property-decorator';
import LoginService from '../services/LoginService';

@Component
export default class Login extends Vue {
  public errorMessage = '';
  public username = '';
  public isLoading = false;
  public isDisabled = false;

  public async mounted() {
    console.log('ResetPassword: mounted()');
  }

  public isValidEmail() {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(this.username) || 'Invalid email';
  }

  public onSubmit() {
    console.log('Login: onSubmit()');
    this.isLoading = true;
    LoginService.resetPassword(this.username)
      .then((response) => {
        console.log(response);
        this.isLoading = false;
        this.isDisabled = true;
        this.errorMessage = 'Please check your email for instructions';
      })
      .catch((error) => {
        console.error(error);
        this.errorMessage = error.description;
      });
  }
}
