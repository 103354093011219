
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PigRunService from '@/services/PigRunService';
import { UpdateA2PMessagingStateRequest } from '@/models/Notification/UpdateA2PMessagingStateRequest';

@Component
export default class NotificationSettings extends Vue {
  // Validation dialog properties.
  public showValidationMessageDialog = false;
  public validationDialogMessage = '';
  public validationDialogTitle = '';

  // Model property that maps to the opt-in checkbox value.
  public a2pMessagingOptInState = false;

  // Model property to display the application-to-person state save message.
  public displayA2PMessagingStateMessage = false;

  // Model property for the application-to-person state save message content. Note that this could be either a confirmation or validation message.
  public a2pMessagingStateMessageContent = '';

  // Model property for the application-to-person state save message colour.
  //
  // Note: Valid values are either 'black' or 'red'.
  public a2pMessagingStateMessageColour = 'black';

  // Property that maps to the display of the privacy policy dialog.
  public displayPrivacyPolicyDialog = false;

  // Property that maps to the display of the terms of service dialog.
  public displayTermsOfServiceDialog = false;

  // Model property corresponding to the phone text field.
  public phone = '';

  // Subscribe to the notificationSettings action. Refer to index.ts.
  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'notificationSettings') {
      // Initialize this component.
      this.initialize();
    }
  });

  // Vue.js lifecycle hook.
  public beforeDestroy() {
    this.unsubscribe();
  }

  // closePrivacyPolicy.
  //
  // Method used to close the privacy policy dialog.
  public async closePrivacyPolicy() {
    this.displayPrivacyPolicyDialog = false;
  }

  // getPhoneNumericsOnly.
  //
  // Method used to get the numeric characters from the phone text field while eliminating all non-numeric characters.
  public getPhoneNumericsOnly(phone: string | undefined): string {
    console.log(
      'NotificationSettings.vue. getPhoneNumericsOnly. Enter method.'
    );

    if (phone == undefined) {
      return '';
    }

    if (phone.length == 0) {
      return '';
    }

    let regularExpression = /\D/g;
    let phoneNumericsOnly: string = phone.replace(regularExpression, '');

    return phoneNumericsOnly;
  }

  // initialize.
  //
  // Method used to initialize this component.
  public async initialize() {
    console.log('NotificationSettings.vue. initialize. Enter method.');

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Get the user detail.
    await PigRunService.getUser()
      .then((response) => {
        console.log(
          'NotificationSettings.vue. initialize. Before logging PigRunService.getUser response.'
        );
        console.log(response);

        // Initialize the phone text field value.
        this.phone = response.data.phone;

        // Initialize the a2pMessagingOptInState checkbox value.
        this.a2pMessagingOptInState = false;

        if (
          response.data.a2PMessagingOptInState != undefined &&
          response.data.a2PMessagingOptInState
        ) {
          this.a2pMessagingOptInState = true;
        }

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'NotificationSettings.vue. initialize. Before logging PigRunService.getUser exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A notification settings service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Notification Settings Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // If there is a confirmation/validation message displayed, remove it.
    this.displayA2PMessagingStateMessage = false;

    // Display the terms of service dialog.
    this.displayTermsOfServiceDialog = true;
  }

  // Vue.js lifecycle hook.
  public mounted() {
    console.log('NotificationSettings.vue. mounted.');
  }

  // displayPrivacyPolicy.
  //
  // Method used to display the privacy policy dialog.
  public displayPrivacyPolicy() {
    console.log(
      'NotificationSettings.vue. displayPrivacyPolicy. Enter method.'
    );

    this.displayPrivacyPolicyDialog = true;
  }

  // saveA2PMessagingStateClick.
  //
  // The saveA2PMessagingStateClick button click event handler.
  public async saveA2PMessagingStateClick() {
    console.log(
      'NotificationSettings.vue. saveA2PMessagingStateClick. Enter method.'
    );

    // Get the phone text field string value numerics only.
    let phoneNumericsOnly: string = this.getPhoneNumericsOnly(this.phone);

    // If the phone number with numerics only is greater than 50 characters long, display a message to the user.
    //
    // Note: The Users.Phone column definition is varchar(50) null.
    if (phoneNumericsOnly.length > 50) {
      this.a2pMessagingStateMessageContent =
        'The phone number specified can, at most, include 50 characters of numerics (0-9).';
      this.a2pMessagingStateMessageColour = 'red';
      this.displayA2PMessagingStateMessage = true;

      return;
    }

    // If the opt-in checkbox has been selected.
    if (this.a2pMessagingOptInState) {
      // A phone number must be specified.
      if (phoneNumericsOnly.length == 0) {
        this.a2pMessagingStateMessageContent =
          'A phone number must be specified.';
        this.a2pMessagingStateMessageColour = 'red';
        this.displayA2PMessagingStateMessage = true;

        return;
      }
    }

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Construct an UpdateA2PMessagingStateRequest object.
    let updateA2PMessagingStateRequest = new UpdateA2PMessagingStateRequest(
      this.a2pMessagingOptInState,
      phoneNumericsOnly
    );

    // Update the application-to-person messaging state for the user.
    await PigRunService.updateA2PMessagingState(updateA2PMessagingStateRequest)
      .then((response) => {
        console.log(
          'NotificationSettings.vue. saveA2PMessagingStateClick. Before logging PigRunService.updateA2PMessagingState response.'
        );
        console.log(response);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'NotificationSettings.vue. saveA2PMessagingStateClick. Before logging PigRunService.updateA2PMessagingState exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A notification settings service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Notification Settings Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Display a confirmation message that the messaging settings have been saved.
    this.a2pMessagingStateMessageContent =
      'Your messaging settings have been saved.';
    this.a2pMessagingStateMessageColour = 'black';
    this.displayA2PMessagingStateMessage = true;
  }
}
