
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';

@Component
export default class DeviceActions extends Vue {
  get device(): Device {
    return this.$store.state.selectedDevice;
  }

  public onQueueCommands() {
    if (this.device.isIridium) {
      this.$store.dispatch('queueCommandsIridiumShow');
    } else {
      this.$store.dispatch('queueCommandsShow');
    }
  }

  public onHistoricData() {
    this.$store.dispatch('historicDataShow');
  }
}
