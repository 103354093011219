export interface IWebPassage {
  uid: number;
  data: number[] | undefined;
  lat: number | undefined;
  lon: number | undefined;
  packetComplexInformation: number | undefined;
  passageDetectedFrequency: number | undefined;
  passageTimeStamp: Date | undefined;
  samplingRate: number | undefined;
  timeEndOfSnippet: Date | undefined;
}

export class WebPassage implements IWebPassage {
  public data: number[] | undefined;
  public lat: number | undefined;
  public lon: number | undefined;
  public packetComplexInformation: number | undefined;
  public passageDetectedFrequency: number | undefined;
  public passageTimeStamp: Date | undefined;
  public samplingRate: number | undefined;
  public timeEndOfSnippet: Date | undefined;

  constructor(public uid: number) {}
}
