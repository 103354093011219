export class StreamMode {
  public static Off = 0;
  public static CellStreamOn = 1;
  public static BluetoothStreamOn = 2;

  public static getModes() {
    return [
      this.Off, // 0
      this.CellStreamOn, // 1
      this.BluetoothStreamOn, // 2
    ];
  }

  public static toString(i: number) {
    return [
      'Off', // 0
      'Cell Stream On', // 1
      'Bluetooth Stream On', // 2
    ][i];
  }
}
