import axios from 'axios';
import { Command } from '../models/Command';
import store from '../store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let token = '';

export default {
  getCommands(uid: number) {
    token = store.getters.token;
    return apiClient.get('/commands/' + uid, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteCommand(commandId: number) {
    token = store.getters.token;
    return apiClient.delete('/commands/' + commandId, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  postCommand(command: Command) {
    token = store.getters.token;
    return apiClient.post('/commands/queue/', command, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  postCommands(commands: Command[]) {
    token = store.getters.token;
    return apiClient.post('/commands/queueList', commands, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
