var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.marker.name)?_c('q-card',{staticClass:"my-card bg-grey-1",attrs:{"square":"","flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"row items-center no-wrap"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"text-grey-brand text-subtitle1 text-bold"},[_vm._v(" Marker Name: "+_vm._s(_vm.marker ? _vm.marker.name : '')+" ")])])]),_c('p',[_vm._v(" Marker Description: "+_vm._s(_vm.marker && _vm.marker.description ? _vm.marker.description : 'AGM')+" ")]),_c('p',[_vm._v("State: "+_vm._s(_vm.marker ? _vm.marker.state : ''))]),(_vm.marker.linkedUid)?_c('p',[_vm._v("Linked UID: "+_vm._s(_vm.marker.linkedUid))]):_vm._e(),_c('p',[_vm._v("ETA to marker: "+_vm._s(_vm.etaFormated))]),(_vm.marker.note)?_c('p',[_vm._v("Note: "+_vm._s(_vm.marker.note))]):_vm._e(),(
        _vm.marker.state === 'TRACK' &&
        !_vm.marker.linkedUid &&
        !_vm.marker.autoLink &&
        (_vm.getuser() === 'admin' || _vm.getuser() === 'superadmin')
      )?_c('span',[_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onAssignUid}},[_vm._v("Assign UID")]),_c('br')],1):_vm._e(),(
        _vm.marker.state === 'TRACK' &&
        _vm.marker.linkedUid &&
        !_vm.marker.autoLink &&
        (_vm.getuser() === 'admin' || _vm.getuser() === 'superadmin')
      )?_c('span',[_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onUnlinkUid}},[_vm._v("Unlink UID")]),_c('br')],1):_vm._e(),(
        _vm.marker.state === 'TRACK' &&
        (_vm.getuser() === 'admin' || _vm.getuser() === 'superadmin')
      )?_c('span',[_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onAddPassage}},[_vm._v("Add Manual Passage")]),_c('br')],1):_vm._e(),(_vm.marker.state === 'TRACK')?_c('span',[_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onVerifyPassage}},[_vm._v("View Passages")]),_c('br')],1):_vm._e(),(_vm.getuser() == 'admin' || _vm.getuser() == 'superadmin')?_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onChangeState}},[_vm._v(_vm._s(_vm.marker.state === 'SKIP' ? 'Change to TRACK' : 'Change to SKIP'))]):_vm._e(),_c('br'),_c('span',[(_vm.getuser() == 'admin' || _vm.getuser() == 'superadmin')?_c('q-btn-dropdown',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","label":"Change Marker Description","disabled":!_vm.isOnline}},[_c('q-list',_vm._l((_vm.markerDescriptions),function(description,index){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:index,attrs:{"clickable":""},on:{"click":function($event){_vm.onChangeDescription(description.toUpperCase())}}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(description))])],1)],1)}),1)],1):_vm._e()],1),_c('br'),(_vm.getuser() == 'admin' || _vm.getuser() == 'superadmin')?_c('q-btn',{staticClass:"q-mb-sm btn-brand",attrs:{"dense":"","no-caps":"","disabled":!_vm.isOnline},on:{"click":_vm.onAddNote}},[_vm._v("Add Note ")]):_vm._e(),_c('br')],1)],1):_c('q-card',{staticClass:"my-card bg-grey-1",attrs:{"square":"","flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"row items-center no-wrap"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"text-h6 text-weight-bold text-grey-brand"},[_vm._v(" No Marker Selected ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }