
import L from 'leaflet';
import MapCommon from '../utils/MapCommon';
import PigRunCommon from '../utils/PigRunCommon';
import { Device } from '../models/Device';
import { PigPath } from '../models/PigPath';
import { Pig } from '../models/Pig';
import { UserMgmt } from '../models/UserMgmt';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';
import { AgmMarker } from '@/models/AgmMarker';
import { UserNotification } from '@/models/UserNotification';
import { User } from '@/models/User';
import UserService from '@/services/UserService';

@Component
export default class SmsNotifications extends Vue {
  @Prop() public readonly markers: AgmMarker[] | undefined;
  @Prop() public readonly notifications: UserNotification[] | undefined;

  public addNotifierDialog = false;
  public infoNotifierDialog = false;

  public launch = false;
  public receive = false;

  public medium = 'sms';

  public passageType = 'verified';
  public manualPassages = false;

  public allMarkers = true;
  public markerNames: string[] = [];

  public tmpSelectedMarkers: string[] = [];
  public markerOptions: string[] = [];

  public infinitePagination = {
    rowsPerPage: 0, // 0==infinite
  };

  public selectedUser: any = '';

  public selectedUserNotification: UserNotification | null = null;
  public userNotifications: UserNotification[] = [];
  public userNotificationVisibleColumns = ['name', 'info-user', 'delete-user'];
  public userNotificationColumns = [
    { name: 'name', label: 'Name', field: 'name', sortable: false },
    { name: 'userId', label: 'UserID', field: 'userId', sortable: false },
    { name: 'launch', label: 'Launch', field: 'launch', sortable: false },
    { name: 'receive', label: 'Receive', field: 'receive', sortable: false },
    {
      name: 'passageType',
      label: 'Passage Type',
      field: 'passageType',
      sortable: false,
    },
    {
      name: 'manualPassage',
      label: 'Manual Passage',
      field: 'manualPassage',
      sortable: false,
    },
    {
      name: 'allMarkers',
      label: 'All Markers',
      field: 'allMarkers',
      sortable: false,
    },
    { name: 'markers', label: 'Markers', field: 'markers', sortable: false },
    { name: 'info-user', field: 'user', label: '' }, // changed to btn in slot
    { name: 'delete-user', field: 'user', label: '' }, // changed to btn in slot
  ];

  get userOptions() {
    if (this.$store.state.admin.users) {
      return this.$store.state.admin.users.map((user: UserMgmt) => {
        return { label: user.name, value: user.id };
      });
    }
    return [];
  }

  get notificationTable() {
    return this.userNotifications;
  }

  get map() {
    return this.$store.state.map;
  }

  get selectedPigPath() {
    return this.$store.state.pigRun.selectedPigPath;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    // if (action.type === 'startPigRun') {
    //     console.log('PigRunManager: startPigRun');
    // }
  });

  // watch the notifications provided by parent component
  @Watch('notifications', { immediate: true, deep: true })
  public notificationsChanged(
    value: UserNotification[],
    oldValue: UserNotification[]
  ) {
    this.userNotifications = this.notifications ? this.notifications : [];
  }

  // onAddUserNotification.
  //
  // The add user notification button click event handler.
  public onAddUserNotification() {
    // reset
    this.selectedUser = '';
    this.passageType = 'verified';

    // Mitch - updated impl.
    this.launch = true;

    // Mitch - previous impl.
    // this.launch = false;

    // Mitch - updated impl.
    this.receive = true;

    // Mitch - previous impl.
    // this.receive = false;
    this.medium = 'sms';

    // Mitch - updated impl.
    this.manualPassages = true;

    // Mitch - previous impl.
    // this.manualPassages = false;
    this.allMarkers = true;
    this.tmpSelectedMarkers = [];

    // populate the available marker options
    this.markerOptions = [];
    if (this.markers) {
      this.markerOptions = this.markers.map((marker: AgmMarker) => marker.name);
    }

    this.addNotifierDialog = true;
  }

  public onUserNotificationAdded() {
    var anyduplicates = false;
    var index = -1;

    for (var i = 0; i < this.userNotifications.length; i++) {
      console.log(this.userNotifications[i].userId);
      console.log(this.selectedUser.value);
      if (this.userNotifications[i].userId == this.selectedUser.value) {
        // duplicate
        anyduplicates = true;
        index = i;
      }
    }

    if (anyduplicates == true) {
      this.userNotifications.splice(index, 1);
    }

    this.userNotifications.push(
      new UserNotification(
        this.selectedUser.label,
        this.selectedUser.value,
        this.launch,
        this.receive,
        this.medium,
        this.passageType,
        this.manualPassages,
        this.allMarkers,
        this.allMarkers ? undefined : this.tmpSelectedMarkers
      )
    );

    console.log(this.userNotifications);
    this.$emit('update-notifications', this.userNotifications);
  }

  public onDeleteUserNotification(props: any) {
    console.log('onDeleteUser');

    this.userNotifications = this.userNotifications.filter(
      (notifications: any) => notifications.userId !== props.row.userId
    );
    this.$emit('update-notifications', this.userNotifications);
  }

  public onUserNotificationInfo(props: any) {
    console.log('onUserNotificationInfo');
    this.selectedUserNotification = props.row;
    this.infoNotifierDialog = true;
  }

  public mounted() {
    console.log('SmsNotifications: mounted()');
    this.$store.dispatch('getLocalUsers');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
