
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group } from '../models/Group';
import { DeviceMgmt } from '../models/DeviceMgmt';
import { UserMgmt } from '../models/UserMgmt';

@Component
export default class UserManagementLocal extends Vue {
  public tab = 'users';

  public localUserDialog = false;

  public selectedGroup: Group | null = null;
  public selectedUser: UserMgmt | null = null;
  public selectedAgm: DeviceMgmt | null = null;

  public groupDialog = false;
  public editUserDialog = false;
  public editAgmDialog = false;
  public infoUserDialog = false;
  public infoAgmDialog = false;

  public groupId: number | null = null;
  public groupName = '';

  public username = '';
  public email = '';
  public phone = '';
  public role = '';

  public roleOptions = ['admin', 'viewer'];

  public uid = '';
  public startingLat = '';
  public startingLon = '';

  get groups(): Group[] {
    if (
      this.$store.state.admin.groups &&
      this.$store.state.admin.groups.length === 1
    ) {
      this.selectedGroup = this.$store.state.admin.groups[0];
    }
    return this.$store.state.admin.groups;
  }

  get agms(): DeviceMgmt[] {
    return this.$store.state.admin.agms;
  }

  get users(): UserMgmt[] {
    return this.$store.state.admin.users;
  }

  get user() {
    return this.$store.state.user;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'localUserManagerShow') {
      this.showDialog();
    }
  });

  public showDialog() {
    this.localUserDialog = true;
    this.$store.dispatch('getLocalUsers');
  }

  public isValidEmail(val: string) {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || 'Invalid email';
  }

  public onNewGroup() {
    console.log('onNewGroup');
    this.groupId = null;
    this.groupName = '';
    this.groupDialog = true;
  }

  public onEditGroup() {
    console.log('onEditGroup');
    if (this.selectedGroup) {
      this.groupId = this.selectedGroup.id ? this.selectedGroup.id : null;
      this.groupName = this.selectedGroup.groupName;
      this.groupDialog = true;
    }
  }

  public onAddGroup() {
    this.$store.dispatch(
      'upsertGroup',
      new Group(this.groupId, this.groupName)
    );
    if (this.selectedGroup) {
      this.selectedGroup.groupName = this.groupName;
    }
  }

  public onNewUser() {
    console.log('onNewUser');
    this.selectedUser = null;
    this.username = '';
    this.email = '';
    this.role = '';
    this.phone = '';
    this.editUserDialog = true;
  }

  public onSaveUser() {
    if (this.editUserDialog) {
      this.editUserDialog = false;
    }
    if (this.infoUserDialog) {
      this.infoUserDialog = false;
    }
    if (this.selectedUser) {
      this.$store.dispatch(
        'upsertLocalUser',
        new UserMgmt(
          this.selectedUser.id,
          this.selectedUser.group,
          this.username,
          this.email,
          this.phone,
          this.role
        )
      );
    } else {
      // Don't need to know the group, api places it in the right one
      this.$store.dispatch(
        'upsertLocalUser',
        new UserMgmt(
          null,
          -99,
          this.username,
          this.email,
          this.phone,
          this.role
        )
      );
    }
  }
  public onNewAgm() {
    console.log('onNewAgm');
    this.uid = '';
    this.startingLat = '';
    this.startingLon = '';
    this.editAgmDialog = true;
  }

  public saveAgm() {
    if (this.editAgmDialog) {
      this.editAgmDialog = false;
    }
    if (this.selectedGroup) {
      this.$store.dispatch(
        'addAgm',
        new DeviceMgmt(
          parseInt(this.uid, 10),
          this.selectedGroup.id as number,
          parseFloat(this.startingLat),
          parseFloat(this.startingLon)
        )
      );
    }
  }

  public onGroupSelect(group: Group) {
    console.log('onGroupSelect');
    this.selectedGroup = group;
    this.$store.dispatch('getAdminUsers', group.id);
    this.$store.dispatch('getAdminAgms', group.id);
  }
  public onDeleteGroup() {
    console.log('onDeleteGroup');
    if (this.selectedGroup) {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Would you like to delete ' + this.selectedGroup.groupName,
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          console.log('>>>> OK');
          if (this.selectedGroup) {
            this.$store.dispatch('deleteGroup', this.selectedGroup.id);
            this.selectedGroup = null;
          }
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    }
  }

  public onUserDetail(user: any) {
    console.log('onUserDetail');
    this.selectedUser = user;
    this.infoUserDialog = true;
  }
  public onEditUser() {
    if (this.selectedUser) {
      this.username = this.selectedUser.name;
      this.email = this.selectedUser.email;
      this.phone = this.selectedUser.phone;
      this.role = this.selectedUser.role;
      this.editUserDialog = true;
    }
  }

  public onDeleteUser() {
    if (this.selectedUser) {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Would you like to delete ' + this.selectedUser.name,
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          console.log('>>>> OK');
          if (this.selectedUser) {
            this.$store.dispatch('deleteLocalUser', this.selectedUser.id);
            this.infoUserDialog = false;
          }
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    }
  }

  public onAgmDetail(agm: any) {
    console.log('onAgmDetail');
    this.selectedAgm = agm;
    this.infoAgmDialog = true;
  }

  public onDeleteAgm() {
    if (this.selectedGroup && this.selectedAgm) {
      this.$q
        .dialog({
          title: 'Confirm',
          message:
            'Would you delete UID ' +
            this.selectedAgm.uid +
            ' from ' +
            this.selectedGroup.groupName,
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          console.log('>>>> OK');
          if (this.selectedAgm) {
            this.$store.dispatch('deleteAgm', this.selectedAgm.uid);
            this.infoAgmDialog = false;
          }
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    }
  }

  public mounted() {
    console.log('UserManagementLocal: mounted()');
    // if (this.user && this.user.role === 'superadmin') {
    //     this.$store.dispatch('getGroups');
    // }
    // if (this.user && this.user.role === 'admin') {
    //     // this.selectedGroup = new Group(-99, 'Group');
    //     this.$store.dispatch('getLocalUsers');
    //     this.$store.dispatch('getLocalAgms');
    // }
  }

  public beforeDestroy() {
    console.log('UserManagement: beforeDestroy()');
  }
}
