import Vue from 'vue';
import Vuex from 'vuex';
import AdminService from '../../services/AdminService';
import UserService from '../../services/UserService';
import DeviceService from '../../services/DeviceService';
import { Group } from '../../models/Group';
import { DeviceMgmt } from '../../models/DeviceMgmt';
import { UserMgmt } from '../../models/UserMgmt';

export interface AdminState {
  groups: Group[];
  users: UserMgmt[] | null;
  agms: DeviceMgmt[] | null;
  allAgms: DeviceMgmt[] | null;
}

export default {
  state: {
    groups: [],
    users: null,
    agms: [],
    allAgms: [],
  } as AdminState,
  mutations: {
    SET_GROUPS(state: AdminState, groups: Group[]) {
      state.groups = groups;
    },
    SET_USERS(state: AdminState, users: any) {
      state.users = users;
    },
    SET_AGMS(state: AdminState, agms: DeviceMgmt[]) {
      state.agms = agms;
    },
    SET_ALL_AGMS(state: AdminState, agms: DeviceMgmt[]) {
      state.allAgms = agms;
    },
  },
  actions: {
    getGroups(context: any, payload: any) {
      AdminService.getGroups()
        .then((response) => {
          console.log(response);
          const groups: Group[] = [];
          for (const group of response.data) {
            groups.push(new Group(group.id, group.groupName));
          }
          context.commit('SET_GROUPS', groups);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upsertGroup(context: any, group: Group) {
      AdminService.postGroup(group)
        .then((response) => {
          console.log(response);
          if (response.data) {
            const groupResponse = new Group(
              response.data.id,
              response.data.groupName
            );
            const updateGroups = context.state.groups.filter(
              (g: Group) => g.id !== group.id
            );
            updateGroups.push(groupResponse);
            context.commit('SET_GROUPS', updateGroups);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteGroup(context: any, groupId: number) {
      AdminService.deleteGroup(groupId)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            const updateGroups = context.state.groups.filter(
              (g: Group) => g.id !== groupId
            );
            console.log(updateGroups);
            context.commit('SET_GROUPS', updateGroups);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLocalUsers(context: any) {
      UserService.getUsers()
        .then((response) => {
          console.log(response);
          const users: UserMgmt[] = [];
          let groupId = 0;
          for (const user of response.data) {
            users.push(
              new UserMgmt(
                user.id,
                user.group,
                user.name,
                user.email,
                user.phone,
                user.role
              )
            );
            groupId = user.group;
          }
          context.commit('SET_GROUPS', [new Group(groupId, '')]);
          context.commit('SET_USERS', users);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upsertLocalUser(context: any, user: UserMgmt) {
      UserService.postUser(user)
        .then((response) => {
          console.log(response);
          if (response.data) {
            const userResponse = new UserMgmt(
              response.data.id,
              response.data.group,
              response.data.name,
              response.data.email,
              response.data.phone,
              response.data.role
            );
            const updateUsers = context.state.users.filter(
              (u: UserMgmt) => u.id !== user.id
            );
            updateUsers.push(userResponse);
            context.commit('SET_USERS', updateUsers);
          }
        })
        .catch((error) => {
          console.error(error);
          context.dispatch(
            'notify',
            'Error adding user - email address already in system'
          );
        });
    },
    deleteLocalUser(context: any, userId: string) {
      UserService.deleteUser(userId)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            const updateUsers = context.state.users.filter(
              (u: UserMgmt) => u.id !== userId
            );
            console.log(updateUsers);
            context.commit('SET_USERS', updateUsers);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getAdminUsers(context: any, groupId: number) {
      AdminService.getUsers(groupId)
        .then((response) => {
          console.log(response);
          const users: UserMgmt[] = [];
          for (const user of response.data) {
            users.push(
              new UserMgmt(
                user.id,
                groupId,
                user.name,
                user.email,
                user.phone,
                user.role
              )
            );
          }
          context.commit('SET_USERS', users);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upsertAdminUser(context: any, user: UserMgmt) {
      AdminService.postUser(user)
        .then((response) => {
          console.log(response);
          if (response.data) {
            const userResponse = new UserMgmt(
              response.data.id,
              response.data.group,
              response.data.name,
              response.data.email,
              response.data.phone,
              response.data.role
            );
            const updateUsers = context.state.users.filter(
              (u: UserMgmt) => u.id !== user.id
            );
            updateUsers.push(userResponse);
            context.commit('SET_USERS', updateUsers);
          }
        })
        .catch((error) => {
          console.error(error);
          context.dispatch(
            'notify',
            'Error adding user - email address already in system'
          );
        });
    },
    deleteAdminUser(context: any, userId: string) {
      AdminService.deleteUser(userId)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            const updateUsers = context.state.users.filter(
              (u: UserMgmt) => u.id !== userId
            );
            console.log(updateUsers);
            context.commit('SET_USERS', updateUsers);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getAllAgms(context: any) {
      DeviceService.getDevices()
        .then((response) => {
          console.log(response);
          const agms: DeviceMgmt[] = [];
          for (const d of response.data) {
            // TODO set imei when merged with iridium
            const a = new DeviceMgmt(d.uId, d.group, d.lat, d.lon);
            a.group = d.groupId;
            agms.push(a);
          }
          context.commit('SET_ALL_AGMS', agms);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAdminAgms(context: any, groupId: number) {
      AdminService.getAgms(groupId)
        .then((response) => {
          console.log(response);
          const agms: DeviceMgmt[] = [];
          for (const d of response.data) {
            // TODO set imei when merged with iridium
            const a = new DeviceMgmt(d.uId, d.group, d.lat, d.lon);
            a.group = d.groupId;
            agms.push(a);
          }
          context.commit('SET_AGMS', agms);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAgm(context: any, uid: number) {
      AdminService.deleteAgm(uid)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            const updateAgms = context.state.agms.filter(
              (d: DeviceMgmt) => d.uid !== uid
            );
            console.log(updateAgms);
            context.commit('SET_AGMS', updateAgms);
            context.dispatch('getAllAgms');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addAgm(context: any, agm: DeviceMgmt) {
      AdminService.addAgm(agm)
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            const updateAgms = context.state.agms;
            updateAgms.push(agm);
            context.commit('SET_AGMS', updateAgms);
            context.dispatch('getAllAgms');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeUserPassword(context: any, newPassword: string) {
      UserService.getUsers()
        .then((response) => {
          if (response && response.data) {
            const userResponse = response.data.find(
              (u: any) => u.id === context.rootState.user.id
            );
            const userUpdatePassword = new UserMgmt(
              userResponse.id,
              userResponse.group,
              userResponse.name,
              userResponse.email,
              userResponse.phone,
              userResponse.role,
              newPassword
            );
            UserService.setPassword(userUpdatePassword)
              .then((passwordResponse) => {
                context.dispatch('notify', 'Password Changed');
              })
              .catch((error) => {
                console.error(error);
                context.dispatch('notify', 'Unable to change password');
              });
          }
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', 'Unable to change password');
        });
    },
  },
};
