import L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet-extra-markers';

export default class PigRunCommon {
  // classes are still defined in LeafletMap component CSS
  public static launchIcon = L.AwesomeMarkers.icon({
    prefix: 'fa',
    icon: 'plane-departure',
    markerColor: 'green',
  });

  public static recieveIcon = L.AwesomeMarkers.icon({
    prefix: 'fa',
    icon: 'plane-arrival',
    markerColor: 'red',
  });

  public static tapIcon = L.divIcon({
    className: 'simpleMarkerTap',
  });

  public static magnetIcon = L.divIcon({
    className: 'simpleMarkerMagnet',
  });

  public static bendIcon = L.divIcon({
    className: 'simpleMarkerBend',
  });

  public static weldIcon = L.divIcon({
    className: 'simpleMarkerWeld',
  });

  public static attachmentIcon = L.divIcon({
    className: 'simpleMarkerAttachment',
  });

  public static teeIcon = L.divIcon({
    className: 'simpleMarkerTee',
  });

  public static pipeIcon = L.divIcon({
    className: 'simpleMarkerPipe',
  });

  public static otherIcon = L.divIcon({
    className: 'simpleMarkerOther',
  });

  public static pigIcon = L.AwesomeMarkers.icon({
    prefix: 'fa',
    icon: 'piggy-bank',
    markerColor: 'darkpurple',
  });

  public static pigIcon_2 = L.AwesomeMarkers.icon({
    prefix: 'fa',
    icon: 'piggy-bank',
    markerColor: 'darkred',
  });

  public static pigRunOptions = {
    color: '#BA55D3',
    opacity: 0.9,
  };

  public static kmlOptions = {
    color: '#37474f',
    weight: 4,
    opacity: 0.8,
  };

  public static kmlSnapMarker = L.divIcon({
    className: 'simpleMarker',
  });

  public static kmlSnapLineOptions = {
    color: 'grey',
    opacity: 0.9,
    weight: 1,
    dashArray: '10, 5',
  };

  public static trackIcon = L.ExtraMarkers.icon({
    icon: '',
    markerColor: 'cyan',
  });

  public static trackIcon_2 = L.ExtraMarkers.icon({
    prefix: 'fa',
    icon: 'fa-angle-double-left',
    markerColor: 'cyan',
  });
  public static trackIcon_3 = L.ExtraMarkers.icon({
    prefix: 'fa',
    icon: 'fa-angle-double-left',
    markerColor: 'red',
  });

  public static skipIcon = L.ExtraMarkers.icon({
    prefix: 'fa',
    icon: 'fa-angle-double-left',
    iconColor: 'black',
    markerColor: 'white',
  });

  public static psgIcon = L.ExtraMarkers.icon({
    prefix: 'fa',
    icon: 'fa-arrow-down',
    iconColor: 'white',
    markerColor: 'black',
  });

  public static metersToMiles(ms: number) {
    return ms * 2.237; // ms to mph
  }

  public static milesToMeters(mi: number) {
    return mi / 2.237; // mph to ms
  }

  public static getIcon(description: string) {
    switch (description) {
      case 'LAUNCH':
        return this.launchIcon;
      case 'RECEIVE':
        return this.recieveIcon;
      case 'SKIP':
        return this.skipIcon;
      case 'TAP':
        return this.tapIcon;
      case 'MAGNET':
        return this.magnetIcon;
      case 'BEND':
        return this.bendIcon;
      case 'WELD':
        return this.weldIcon;
      case 'ATTACHMENT':
        return this.attachmentIcon;
      case 'TEE':
        return this.teeIcon;
      case 'PIPE':
        return this.pipeIcon;
      case 'OTHER':
        return this.otherIcon;
      case 'VALVE':
        return this.magnetIcon;
      case 'TRACK_NotVerified':
        return this.trackIcon_2;
      default:
        return this.trackIcon;
    }
  }
}
