
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RemoveDevice extends Vue {
  public device = false;
  public uid = '';
  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'removeDevice') {
      this.showDialog();
    }
  });

  public showDialog() {
    this.device = true;
  }

  public mounted() {
    console.log('RemoveDevice: mounted()');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
