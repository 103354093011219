import { WebAuth } from 'auth0-js';
import axios from 'axios';

const auth0: WebAuth = new WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN!,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID!,
  audience: 'https://propipe-api.com/', // NOTE: that trailing slash is very important
  responseType: 'token',
  redirectUri: process.env.VUE_APP_REDIRECT_URI,
  // ask for all roles and user profile, jwt will only be signed with roles that have been assigned
  scope: 'openid profile email admin:propipe admin:company user:company',
});

export default {
  getUserInfo(token: string) {
    console.log('getuserinfo');
    return new Promise((resolve, reject) => {
      auth0.client.userInfo(token, (err, user) => {
        if (err) {
          reject(err);
        } else {
          resolve(user);
        }
      });
    });
  },
  parseHash(hash: string) {
    console.log('parsehash');
    return new Promise((resolve, reject) => {
      auth0.parseHash(
        {
          hash,
        },
        (err, result) => {
          console.log('callback');
          if (err) {
            reject(err);
          }
          if (result) {
            resolve(result);
          }
        }
      );
    });
  },
  login(username: string, password: string) {
    // this needs AuthHandler to handle the redirect
    return new Promise((resolve, reject) => {
      auth0.login(
        {
          realm: process.env.VUE_APP_USER_DB,
          username,
          password,
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  },
  refreshToken() {
    return new Promise((resolve, reject) => {
      auth0.checkSession({}, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },
  resetPassword(email: string) {
    const apiClient = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return apiClient.post('/dbconnections/change_password', {
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      email,
      connection: process.env.VUE_APP_USER_DB,
    });
  },
};
