import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './quasar';
import L from 'leaflet';
import 'setimmediate'; // needed for logdna

// FIX for leaflet/webpack marker bug
// see https://github.com/PaulLeCam/react-leaflet/issues/255
delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
