// CompletedPigRunBroadcastCommand.
//
// Class used as a container to encapsulate the completed pig run broadcast command(s).
export class CompletedPigRunBroadcastCommand {
  // broadcastCommands
  public commandList: string[];

  // PigRun.RunId.
  public runId: number;

  // Constructor.
  public constructor(runId: number, commandList: string[]) {
    this.runId = runId;
    this.commandList = commandList;
  }
}
