export interface IDeviceMgmt {
  uid: number;
  group?: number;
  lat?: number;
  lon?: number;
  imei?: number;
}

export class DeviceMgmt implements IDeviceMgmt {
  constructor(
    public uid: number,
    public group?: number,
    public lat?: number,
    public lon?: number,
    public imei?: number
  ) {}
}
