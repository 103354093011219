
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device } from '../models/Device';
import { Passage } from '../models/Passage';
import { StreamMode } from '../models/StreamMode';
import { PassageSize } from '../models/PassageSize';
import { PassageState } from '../models/PassageState';
import { PigRun } from '../models/PigRun';
import { MarkerPassage } from '@/models/MarkerPassage';
import DeviceActions from '@/components/DeviceActions.vue';
import ViewerDeviceActions from '@/components/ViewerDeviceActions.vue';
import * as turf from '@turf/turf';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
  components: {
    DeviceActions,
    ViewerDeviceActions,
  },
})
export default class DeviceInfo extends Vue {
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // Cellular carrier info section.
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  public cellularCarrierInfoActiveCarrier: any;
  public cellularCarrierInfoPrimaryCarrier: any;
  public cellularCarrierInfoRegistrationStatus: any;

  get displayCellularCarrierInfo(): boolean {
    let displayCellularCarrierInfoReturn = false;

    // If the selected device is not undefined.
    if (this.$store.state.selectedDevice != undefined) {
      let selectedDeviceUid = this.$store.state.selectedDevice.uid;

      if (this.$store.state.cellularCarrierInfoArray != undefined) {
        let elementIndex: number =
          this.$store.state.cellularCarrierInfoArray.findIndex(
            (element: any) => element.uid == selectedDeviceUid
          );

        if (elementIndex != -1) {
          displayCellularCarrierInfoReturn = true;

          this.cellularCarrierInfoActiveCarrier =
            this.$store.state.cellularCarrierInfoArray[
              elementIndex
            ].activeCarrier;
          this.cellularCarrierInfoPrimaryCarrier =
            this.$store.state.cellularCarrierInfoArray[
              elementIndex
            ].primaryCarrier;
          this.cellularCarrierInfoRegistrationStatus =
            this.$store.state.cellularCarrierInfoArray[
              elementIndex
            ].registrationStatus;
        }
      }
    }

    return displayCellularCarrierInfoReturn;
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // Disk usage section.
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  @Watch('device', { immediate: true, deep: true })
  public selectedDeviceChanged(value: Device, oldValue: Device) {
    console.log('DeviceInfo.vue. selectedDeviceChanged. Enter method.');

    this.isAutoDownload = value.isAutoDownload as boolean;
    this.CheckFirmware();
  }

  // Disk usage display properties.
  public displayDiskUsageBySizeAsWarning = false;
  public diskUseMBytes: any;
  public diskSizeMBytes: any;

  public displayDiskUsageByFileCountAsWarning = false;
  public numberOfflineFiles: any;
  public numberOnlineFiles: any;

  // Computed property getter.
  //
  // Disk usage (by size).
  get displayDiskUsageBySize(): boolean {
    let displayDiskUsageBySizeReturn = false;

    if (this.$store.state.selectedDevice != undefined) {
      console.log(
        'DeviceInfo.vue. displayDiskUsageBySize. this.$store.state.selectedDevice != undefined.'
      );

      let selectedDeviceUid = this.$store.state.selectedDevice.uid;

      if (this.$store.state.diskUseArray != undefined) {
        console.log(
          'DeviceInfo.vue. displayDiskUsageBySize. this.$store.state.diskUseArray != undefined.'
        );

        let elementIndex: number = this.$store.state.diskUseArray.findIndex(
          (element: any) => element.uid == selectedDeviceUid
        );

        if (elementIndex != -1) {
          displayDiskUsageBySizeReturn = true;

          this.diskUseMBytes =
            this.$store.state.diskUseArray[elementIndex].diskUseMBytes;
          this.diskSizeMBytes =
            this.$store.state.diskUseArray[elementIndex].diskSizeMBytes;

          if (this.diskUseMBytes / this.diskSizeMBytes > 0.5) {
            this.displayDiskUsageBySizeAsWarning = true;
          } else {
            this.displayDiskUsageBySizeAsWarning = false;
          }
        }
      }
    }

    return displayDiskUsageBySizeReturn;
  }

  // Computed property getter.
  //
  // Disk usage (by file count).
  get displayDiskUsageByFileCount(): boolean {
    let displayDiskUsageByFileCountReturn = false;

    this.displayDiskUsageByFileCountAsWarning = false;

    if (this.$store.state.selectedDevice != undefined) {
      console.log(
        'DeviceInfo.vue. displayDiskUsageByFileCount. this.$store.state.selectedDevice != undefined.'
      );

      let selectedDeviceUid = this.$store.state.selectedDevice.uid;

      if (this.$store.state.diskUseArray != undefined) {
        console.log(
          'DeviceInfo.vue. displayDiskUsageByFileCount. this.$store.state.diskUseArray != undefined.'
        );

        let elementIndex: number = this.$store.state.diskUseArray.findIndex(
          (element: any) => element.uid == selectedDeviceUid
        );

        if (elementIndex != -1) {
          displayDiskUsageByFileCountReturn = true;

          this.numberOfflineFiles =
            this.$store.state.diskUseArray[elementIndex].numberOfflineFiles;
          this.numberOnlineFiles =
            this.$store.state.diskUseArray[elementIndex].numberOnlineFiles;

          console.log(this.numberOfflineFiles);
          console.log(this.numberOnlineFiles);

          if (
            this.numberOfflineFiles != undefined &&
            this.numberOfflineFiles > 500
          ) {
            this.displayDiskUsageByFileCountAsWarning = true;
          }

          if (
            this.numberOnlineFiles != undefined &&
            this.numberOnlineFiles > 500
          ) {
            this.displayDiskUsageByFileCountAsWarning = true;
          }
        }
      }
    }

    return displayDiskUsageByFileCountReturn;
  }

  public isAutoDownload = false;

  get showDeviceInfo(): boolean {
    return this.$store.state.showDeviceInfo;
  }

  get device(): Device {
    return this.$store.state.selectedDevice;
  }

  get expectedWakeupTime(): string {
    if (
      this.device.lastExpectedWakeupTime &&
      dayjs.utc(this.device.lastExpectedWakeupTime + 'Z') > dayjs.utc()
    ) {
      return this.device.lastExpectedWakeupTime.toString().slice(0, -7);
    }

    return '';
  }

  get shutdownTime(): string {
    if (this.device.lastShutdownTime) {
      return this.device.lastShutdownTime.toString().slice(0, -7);
    }

    return '';
  }

  get lastPassage(): any {
    if (this.$store.state.selectedDevice.lastPassage) {
      return this.$store.state.selectedDevice.lastPassage;
    }

    return null;
  }

  get lastPassagesData(): Passage[] {
    return this.$store.state.lastPassages;
  }

  get lastStreamMode(): any {
    return this.$store.state.selectedDevice.lastStreamMode;
  }

  get map() {
    return this.$store.state.map;
  }

  get activePigRuns(): PigRun[] {
    return this.$store.state.pigRun.activePigRuns;
  }

  get linkedPigRun(): string {
    for (const pigRun of this.activePigRuns) {
      if (pigRun && pigRun.markers) {
        for (const marker of pigRun.markers) {
          if (marker.linkedUid === this.device.uid) {
            return pigRun.pigPath.pigRunName;
          }
        }
      }
    }

    return '';
  }

  get linkedMarker(): string {
    for (const pigRun of this.activePigRuns) {
      if (pigRun && pigRun.markers) {
        for (const marker of pigRun.markers) {
          if (marker.linkedUid === this.device.uid) {
            return marker.name;
          }
        }
      }
    }

    return '';
  }

  get autoLinkDistance() {
    return this.$store.state.pigRun.autoLinkDistance;
  }

  get autoLinkedMarkers(): string[] {
    const autoLinkedMarkers = [];

    if (this.device.lat && this.device.lon) {
      for (const pigRun of this.activePigRuns) {
        if (
          pigRun &&
          pigRun.markers &&
          pigRun.pigPath &&
          pigRun.pigPath.agms &&
          pigRun.pigPath.agms.includes(this.device.uid)
        ) {
          for (const marker of pigRun.markers) {
            if (marker.autoLink && marker.lat && marker.lon) {
              const agmLocation = turf.point([
                this.device.lon,
                this.device.lat,
              ]);
              const markerLocation = turf.point([marker.lon, marker.lat]);
              const distance = turf.distance(agmLocation, markerLocation, {
                units: 'meters',
              });

              if (distance < this.autoLinkDistance) {
                autoLinkedMarkers.push(marker.name);
              }
            }
          }
        }
      }
    }

    return autoLinkedMarkers;
  }

  public UserRole = '';
  public getuser() {
    this.UserRole = this.$store.state.user.role;

    return this.UserRole;
  }

  public showGeoMode = false;

  public CheckFirmware() {
    if (this.device.firmwareVersion) {
      if (this.device.firmwareVersion > 2.3) {
        this.showGeoMode = true;
      } else {
        this.showGeoMode = false;
      }
    } else {
      this.showGeoMode = false;
    }
  }

  public goToMarker(markerName: string) {
    console.log('DeviceInfo.vue. goToMarker(). Enter method.');

    for (const pigRun of this.activePigRuns) {
      if (pigRun && pigRun.markers) {
        for (const marker of pigRun.markers) {
          if (marker.name === markerName) {
            this.map.flyTo([marker.lat, marker.lon], 16);
          }
        }
      }
    }
  }

  public lastStreamModeText() {
    if (StreamMode.getModes().includes(this.lastStreamMode)) {
      return StreamMode.toString(this.lastStreamMode);
    }

    return 'BT/Cell';
  }

  public passageStateText() {
    if (this.device.psgState !== undefined) {
      return PassageState.byIdx(this.device.psgState);
    }

    return 'Unknown';
  }

  public passageSizeText() {
    if (this.device.psgSize !== undefined) {
      return PassageSize.byIdx(this.device.psgSize);
    }

    return 'Unknown';
  }

  public formatDetectorSetting(setting: number) {
    if (setting === 0) {
      return 'Mag';
    }

    return setting;
  }

  public lastSensitivityText(sensitivity: number) {
    if (sensitivity === 0) {
      return 'UL';
    }

    if (sensitivity === 1) {
      return 'VL';
    }

    if (sensitivity === 2) {
      return 'L';
    }

    if (sensitivity === 3) {
      return 'M';
    }

    if (sensitivity === 4) {
      return 'H';
    }

    if (sensitivity === 5) {
      return 'VH';
    }

    if (sensitivity === 6) {
      return 'UH';
    }

    return '';
  }

  public formatGeoSetting(setting: number) {
    if (setting === 0) {
      return 'Low';
    } else if (setting === 1) {
      return 'Medium';
    } else if (setting === 2) {
      return 'High';
    } else if (setting === 3) {
      return 'Very High';
    } else {
      return 'Unknown';
    }
  }

  public mounted() {
    console.log('DeviceInfo.vue. mounted. Enter method.');
  }

  public beforeDestroy() {}

  public showChart(passage: Passage) {
    console.log('DeviceInfo.vue. showChart.');
    console.log(passage);

    // Create an array of Passage.Aid value(s).
    let passageIdsToUpdate = [];

    // Push the passage.Id value into the passageIdsToUpdate array.
    passageIdsToUpdate.push(passage.passageId);

    // Dispatch the action addPassageIdsToUpdate, similar to the ReportManager.vue implementation.
    //
    // Note: This Passage.Aid value is used in the PassageDialog.vue component, specifically the onSavePassageTime component method.
    this.$store.dispatch('addPassageIdsToUpdate', passageIdsToUpdate);
    this.$store.dispatch('setPassageDialogSource', 'selected_passage');
    this.$store.dispatch('passageChartShow', passage);
  }

  public showStreamer(uid: number) {
    console.log('DeviceInfo: showStreamer()');

    this.$store.dispatch('realtimeStreamerShow', uid);
  }

  public onQueueCommands() {
    if (this.device.isIridium) {
      this.$store.dispatch('queueCommandsIridiumShow');
    } else {
      this.$store.dispatch('queueCommandsShow');
    }
  }

  public onHistoricData() {
    this.$store.dispatch('historicDataShow');
  }

  public onAutoDownload() {
    this.$store.dispatch('setAutoDownload', {
      uid: this.device.uid,
      isAutoDownload: this.isAutoDownload,
    });
  }
}
