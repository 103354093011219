// ReportDataForCompletedPigRunRequest.
//
// Class representing a report data request for a completed pig run.
export class ReportDataForCompletedPigRunRequest {
  // q-table pagination property: descending. Not currently used by the underlying web api.
  public descending: boolean;

  // Include verified passages only.
  public includeVerifiedPassagesOnly: boolean;

  // q-table pagination property: page.
  public page: number;

  // q-table pagination property: rowsPerPage.
  public rowsPerPage: number;

  // PigRun.RunId.
  public runId: number;

  // q-table pagination property: sortBy. Not currently used by the underlying web api.
  public sortBy: string;

  // Constructor.
  public constructor(runId: number) {
    this.runId = runId;
    this.includeVerifiedPassagesOnly = false;

    this.page = -1;
    this.rowsPerPage = -1;
    this.sortBy = '';
    this.descending = false;
  }
}
