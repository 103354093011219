
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';
import { Command } from '../models/Command';
import { CommandType } from '../models/CommandType';
import { PowerMode } from '../models/PowerMode';
import { RecordMode } from '../models/RecordMode';
import { ConnectionSchedule } from '../models/ConnectionSchedule';
import { StreamEnable } from '../models/StreamEnable';
import { StreamMode } from '../models/StreamMode';
import { RecordModee } from '../models/RecordModee';
import { SDCommands } from '@/models/SDCommands';
import { GeoCommand } from '@/models/GeoGainCommand';
import { string } from 'node_modules/@amcharts/amcharts4/core';
import * as dayjs from 'dayjs';
import { DownloadRecordedPassageDataRequest } from '@/models/ReportManager/DownloadRecordedPassageDataRequest';
import { DownloadRecordedRawDataRequest } from '@/models/DownloadRecordedRawData/DownloadRecordedRawDataRequest';
import PigRunService from '@/services/PigRunService';
import DateCommon from '../utils/DateCommon';
@Component
export default class QueueCommands extends Vue {
  public commandDialog = false;
  public newcommandDisplay = false;
  public HSPdisplay = false;
  public FirmwareVersionMajor = 0;

  get device(): Device {
    return this.$store.state.selectedDevice;
  }

  get queuedCommands(): Command[] {
    return this.$store.state.command.commands;
  }

  public selectedPowerMode = '';
  public powerModeOptions = PowerMode.getModes();

  public selectedConnectionSchedule = '';
  public connectionScheduleOptions = ConnectionSchedule.getModes();

  public selectedRecordMode = '';
  public recordModeOptions = RecordMode.getModes();

  public selectedRecordModee = '';
  public recordModeOptionss = RecordMode.getModes();

  public selectedSdMode = '';
  public SdModeOptions = SDCommands.getModes();
  public SdModeLimitedOptions = SDCommands.getLimitedModes();

  public selectedGeoMode = '';
  public GeoModeOptions = GeoCommand.getModes();

  public selectedStreamMode = '';
  public streamModeOptions = StreamEnable.getModes();

  // converted to string on input change
  public fsZero: number | string = 0;
  public fsOne: number | string = 22;
  public fsTwo: number | string = 22;
  public fsThree: number | string = 22;
  public enabledZero = true;
  public enabledOne = true;
  public enabledTwo = false;
  public enabledThree = false;
  public enabledHSP: boolean | null = null;
  public sensitivityZero = {
    label: 'High',
    value: 4,
  };
  public sensitivityOne = {
    label: 'High',
    value: 4,
  };
  public sensitivityTwo = {
    label: 'High',
    value: 4,
  };
  public sensitivityThree = {
    label: 'High',
    value: 4,
  };
  public sensitivityOptions = [
    {
      label: 'Ultra Low',
      value: 0,
    },
    {
      label: 'Very Low',
      value: 1,
    },
    {
      label: 'Low',
      value: 2,
    },
    {
      label: 'Medium',
      value: 3,
    },
    {
      label: 'High',
      value: 4,
    },
    {
      label: 'Very High',
      value: 5,
    },
    {
      label: 'Ultra High',
      value: 6,
    },
  ];
  public selectedCommands: Command[] = [];

  public columns = [
    {
      name: 'timeStampSent',
      field: 'timeStampSent',
      label: 'Sent',
      align: 'left',
    },
    {
      name: 'commandType',
      field: this.showType,
      align: 'center',
      label: 'Type',
    },
    { name: 'parameters', field: this.showPayload, label: 'Parameters' },
    { name: 'commandId', field: 'commandId', label: 'Action' },
  ];

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'queueCommandsShow') {
      this.showDialog();
    }
    if (action.type === 'queueCommandsClose') {
      this.closeDialog();
    }
  });

  public isStreamingAllowed() {
    if (
      (this.device.bluetooth === 0 || this.device.bluetooth === undefined) &&
      this.device.lastStreamMode !== StreamMode.BluetoothStreamOn
    ) {
      return true;
    }
    if (
      (this.device.bluetooth === 0 || this.device.bluetooth === undefined) &&
      this.device.lastStreamMode !== undefined
    ) {
      return true;
    }
    return false; // no control packets sent? streaming NOT allowed
  }

  public isValidFrequency(model: number) {
    return !(model >= 10 && model <= 30);
  }

  public toProperWord(text: string) {
    // camel case to proper words
    if (text == 'SdMode') {
      return 'SD Mode';
    } else if (text == 'GeoMode') {
      return 'Geo Gain Mode';
    } else if (text == 'agmshutdwn') {
      return 'AGM Shutdown';
    } else if (text == 'foldstate') {
      return 'Fold State';
    } else if (text == 'cellofflinedownloadstate') {
      return 'Offline Download State';
    } else if (text == 'downloadrange') {
      return 'Download Range';
    }

    return (
      text.charAt(0).toUpperCase() + text.replace(/([A-Z])/g, ' $1').slice(1)
    );
  }

  public sensitivityText(sensitivity: number) {
    if (sensitivity === 0) {
      return 'UL';
    }
    if (sensitivity === 1) {
      return 'VL';
    }
    if (sensitivity === 2) {
      return 'L';
    }
    if (sensitivity === 3) {
      return 'M';
    }
    if (sensitivity === 4) {
      return 'H';
    }
    if (sensitivity === 5) {
      return 'VH';
    }
    if (sensitivity === 6) {
      return 'UH';
    }
    return '';
  }

  public showPayload(row: any) {
    // row type==Command, but I don't have the payload defined so there may be type issues

    if (!row.parameters) {
      return '';
    }
    if (row.commandType === CommandType.PowerMode) {
      return this.toProperWord(row.parameters.powerMode);
    } else if (row.commandType === CommandType.RecordMode) {
      return this.toProperWord(row.parameters.recordMode);
    } else if (row.commandType === CommandType.FrequencySelect) {
      if (row.parameters.HighSpeedPulse == null) {
        return (
          row.parameters.enable0 +
          '/' +
          row.parameters.frequency0 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity0) +
          ', ' +
          row.parameters.enable1 +
          '/' +
          row.parameters.frequency1 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity1) +
          ', ' +
          row.parameters.enable2 +
          '/' +
          row.parameters.frequency2 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity2) +
          ', ' +
          row.parameters.enable3 +
          '/' +
          row.parameters.frequency3 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity3)
        );
      } else {
        return (
          row.parameters.enable0 +
          '/' +
          row.parameters.frequency0 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity0) +
          ', ' +
          row.parameters.enable1 +
          '/' +
          row.parameters.frequency1 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity1) +
          ', ' +
          row.parameters.enable2 +
          '/' +
          row.parameters.frequency2 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity2) +
          ', ' +
          row.parameters.enable3 +
          '/' +
          row.parameters.frequency3 +
          '/' +
          this.sensitivityText(row.parameters.sensitivity3) +
          ', HSP = ' +
          row.parameters.HighSpeedPulse
        );
      }
    } else if (row.commandType === CommandType.StreamEnable) {
      return row.parameters.streamEnabled;
    } else if (row.commandType === CommandType.SdMode) {
      return row.parameters.sdMode;
    } else if (row.commandType === CommandType.GeoMode) {
      return row.parameters.geoMode;
    } else if (row.commandType === CommandType.AGMShutDwn) {
      return '';
    } else if (row.commandType === CommandType.FoldState) {
      if (row.parameters.UnfoldedAntennaDetectorEnable) {
        return this.FoldAntStateOptions[1].label;
      } else {
        return this.FoldAntStateOptions[0].label;
      }
      //return this.FoldAntStateOptions[row.parameters.UnfoldedAntennaDetectorEnable].label
    } else if (row.commandType === CommandType.CellOfflineDownloadState) {
      if (row.parameters.OfflineDownloadState) {
        return this.CellOfflineDownloadStateOptions[1].label;
      } else {
        return this.CellOfflineDownloadStateOptions[0].label;
      }
    } else if (row.commandType === CommandType.DownloadRange) {
      var a, b, c;
      a = row.parameters.FileNamesStartDate.substring(
        10,
        row.parameters.FileNamesStartDate.length - 7
      );
      b = row.parameters.FileNamesEndDate.substring(
        10,
        row.parameters.FileNamesEndDate.length - 7
      );
      c = a + '/' + b;
      return c;
    }
    return row.parameters;
  }

  public showType(row: any) {
    return this.toProperWord(row.commandType);
  }

  public onDeleteCommand(commandId: number) {
    this.$store.dispatch('deleteCommand', commandId);
  }

  public showDialog() {
    this.commandDialog = true;

    // clear any previous values
    this.selectedPowerMode = '';
    this.selectedConnectionSchedule = ConnectionSchedule.ConnectEvery30Minutes;
    this.selectedRecordMode = '';
    this.selectedStreamMode = '';
    this.selectedSdMode = '';
    this.selectedGeoMode = '';

    this.DownloadStartTime = dayjs
      .default(new Date())
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    this.DownloadEndTime = this.DownloadStartTime;

    if (this.device.lastRecordMode != null) {
      if (this.device.lastRecordMode == 'Off') {
        this.selectedRecordMode = 'Off';
      } else {
        this.selectedRecordMode = 'On';
      }
    }

    if (this.device.lastFrequency0Setting != null) {
      this.fsZero = this.device.lastFrequency0Setting;
    } else {
      this.fsZero = 0;
    }
    if (this.device.lastFrequency1Setting != null) {
      this.fsOne = this.device.lastFrequency1Setting;
    } else {
      this.fsOne = 22;
    }
    if (this.device.lastFrequency2Setting != null) {
      this.fsTwo = this.device.lastFrequency2Setting;
    } else {
      this.fsTwo = 22;
    }
    if (this.device.lastFrequency3Setting != null) {
      this.fsThree = this.device.lastFrequency3Setting;
    } else {
      this.fsThree = 22;
    }

    if (this.device.lastEnable0 != null) {
      this.enabledZero = this.device.lastEnable0;
    } else {
      this.enabledZero = true;
    }
    if (this.device.lastEnable1 != null) {
      this.enabledOne = this.device.lastEnable1;
    } else {
      this.enabledOne = true;
    }
    if (this.device.lastEnable2 != null) {
      this.enabledTwo = this.device.lastEnable2;
    } else {
      this.enabledTwo = false;
    }
    if (this.device.lastEnable3 != null) {
      this.enabledThree = this.device.lastEnable3;
    } else {
      this.enabledThree = false;
    }

    if (this.device.lastSensitivity0 != null) {
      this.sensitivityZero =
        this.sensitivityOptions[this.device.lastSensitivity0];
    } else {
      this.sensitivityZero = {
        label: 'High',
        value: 4,
      };
    }
    if (this.device.lastSensitivity1 != null) {
      this.sensitivityOne =
        this.sensitivityOptions[this.device.lastSensitivity1];
    } else {
      this.sensitivityOne = {
        label: 'High',
        value: 4,
      };
    }
    if (this.device.lastSensitivity2 != null) {
      this.sensitivityTwo =
        this.sensitivityOptions[this.device.lastSensitivity2];
    } else {
      this.sensitivityTwo = {
        label: 'High',
        value: 4,
      };
    }
    if (this.device.lastSensitivity3 != null) {
      this.sensitivityThree =
        this.sensitivityOptions[this.device.lastSensitivity3];
    } else {
      this.sensitivityThree = {
        label: 'High',
        value: 4,
      };
    }

    // Get Selected Device & Check Firmware Version

    if (this.device.firmwareVersion) {
      this.FirmwareVersionMajor = Math.floor(this.device.firmwareVersion);

      if (this.device.firmwareVersion > 2.4) {
        this.newcommandDisplay = true;
      } else {
        this.newcommandDisplay = false;
      }

      if (this.device.firmwareVersion >= 2.6) {
        this.HSPdisplay = true;
        this.enabledHSP = false;
      } else {
        this.HSPdisplay = false;
        this.enabledHSP = null;
      }
    } else {
      this.newcommandDisplay = false;
    }

    this.$store.dispatch('getCommands', this.device.uid);
  }

  public closeDialog() {
    this.commandDialog = false;
  }

  // params for commands that are not enabled yet
  //   streamEnabled:
  //     type: boolean
  //     description: Enable steaming mode
  //   newPort:
  //     type: integer
  //     description: New port number for the AGM
  //   newIP:
  //     type: string
  //     description: New IP address

  public submitPowerMode() {
    console.log('submitPowerMode(): ' + this.selectedPowerMode);
    const params: any = {
      powerMode: PowerMode.toType(
        this.powerModeOptions.indexOf(this.selectedPowerMode)
      ),
    };
    if (
      this.selectedPowerMode === PowerMode.Hibernate ||
      this.selectedPowerMode === PowerMode.LowPower
    ) {
      // attach connection schedlue for Hibernate/LowPower mode
      params.connectSchedule = ConnectionSchedule.toType(
        this.connectionScheduleOptions.indexOf(this.selectedConnectionSchedule)
      );
    }
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.PowerMode, new Date(), params)
    );
  }

  public submitRecordMode() {
    console.log('submitRecordMode(): ' + this.selectedRecordMode);
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.RecordMode, new Date(), {
        recordMode: RecordMode.toType(
          this.recordModeOptions.indexOf(this.selectedRecordMode)
        ),
      })
    );
  }

  public submitSdMode() {
    debugger;
    console.log(CommandType.SdMode);

    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.SdMode, new Date(), {
        //SDMode: this.SDModeOptions.indexOf(this.selectedSDMode)
        SdMode: this.selectedSdMode,
      })
    );
  }

  public submitGeoMode() {
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.GeoMode, new Date(), {
        GeoMode: this.selectedGeoMode,
      })
    );
  }

  public submitFrequencySelect() {
    console.log(
      'submitFrequencySelect(): ' +
        this.fsZero +
        '/' +
        this.fsOne +
        '/' +
        this.fsTwo +
        '/' +
        this.fsThree
    );

    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.FrequencySelect, new Date(), {
        frequency0: parseInt(this.fsZero as string, 10),
        frequency1: parseInt(this.fsOne as string, 10),
        frequency2: parseInt(this.fsTwo as string, 10),
        frequency3: parseInt(this.fsThree as string, 10),
        sensitivity0: this.sensitivityZero.value,
        sensitivity1: this.sensitivityOne.value,
        sensitivity2: this.sensitivityTwo.value,
        sensitivity3: this.sensitivityThree.value,
        enable0: this.enabledZero,
        enable1: this.enabledOne,
        enable2: this.enabledTwo,
        enable3: this.enabledThree,
        HighSpeedPulse: this.enabledHSP,
      })
    );
  }

  public submitDeviceInfo() {
    console.log('submitDeviceInfo()');
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.DeviceInfo, new Date(), {})
    );
  }

  public submitShutdownAGM() {
    console.log('submitShutdownAGM()');
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.AGMShutDwn, new Date(), {})
    );
  }

  public submitDetectionCountReset() {
    console.log('submitDetectionCountReset()');
    this.$store.dispatch(
      'postCommand',
      new Command(
        this.device.uid,
        CommandType.DetectionCountReset,
        new Date(),
        {}
      )
    );
  }

  public submitDeviceRestart() {
    console.log('submitDeviceRestart()');
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.DeviceRestart, new Date(), {})
    );
  }

  public submitControlSettings() {
    console.log('submitControlSettings()');
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.ControlSettings, new Date(), {})
    );
  }

  public submitStreamMode() {
    console.log('submitControlSettings() ' + this.selectedStreamMode);

    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.StreamEnable, new Date(), {
        streamEnabled:
          this.selectedStreamMode === StreamEnable.On ? true : false,
      })
    );
  }

  public sumbitFoldStateCommand() {
    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.FoldState, new Date(), {
        UnfoldedAntennaDetectorEnable: this.FoldAntState.value,
      })
    );
  }

  // public sumbitCellOfflineDownloadStateCommand()
  // {
  //     this.$store.dispatch('postCommand', new Command(this.device.uid, CommandType.CellOfflineDownloadState, new Date(),
  //     {
  //         OfflineDownloadState: this.CellOfflineDownloadState.value
  //     }));
  // }

  public DownloadStartTime = '';
  public DownloadEndTime = '';

  // submitDownloadRangeCommand.
  //
  // Click event handler for the download within time range send command.
  public submitDownloadRangeCommand() {
    let isValidStartDate: boolean = DateCommon.IsDateAsStringValid(
      this.DownloadStartTime
    );
    let isValidEndDate: boolean = DateCommon.IsDateAsStringValid(
      this.DownloadEndTime
    );

    // If the start date and end date are valid dates.
    if (isValidStartDate && isValidEndDate) {
      let startDate: Date;
      let endDate: Date;

      // Construct Date objects for the start date and end date.
      startDate = DateCommon.ConvertDateAsStringToDate(this.DownloadStartTime);
      endDate = DateCommon.ConvertDateAsStringToDate(this.DownloadEndTime);

      // If the start date is before the end date, the command will be submitted to the web api. Note that
      // the getTime() function returns the number of milliseconds since the epoch.
      if (startDate.getTime() < endDate.getTime()) {
        this.$store.dispatch(
          'postCommand',
          new Command(this.device.uid, CommandType.DownloadRange, new Date(), {
            FileType: this.DownloadRange.value,
            FileNamesStartDate: startDate,
            FileNamesEndDate: endDate,
          })
        );
      }
    }
  }

  public downloadLimitedRangeCommandDateValidationFailure = false;
  public downloadLimitedRangeCommandDateValidationFailureMessage = '';

  public showValidationMessageDialog = false;
  public validationDialogTitle = '';
  public validationDialogMessage = '';

  // Download within Time Range.
  //     - Select download option = Passages
  //     - Select download option = RAW - 2 Mins Max
  //
  // Send command button click event handler.
  public async submitDownloadLimitedRangeCommand() {
    console.log(
      'QueueCommands.vue. submitDownloadLimitedRangeCommand. Enter method.'
    );

    // Initialize the download limited range command date validation failure to false.
    this.downloadLimitedRangeCommandDateValidationFailure = false;

    // If the start date is not valid, display a validation message.
    if (!DateCommon.IsDateAsStringValid(this.DownloadStartTime)) {
      this.downloadLimitedRangeCommandDateValidationFailure = true;
      this.downloadLimitedRangeCommandDateValidationFailureMessage =
        'The specified start date/time is not valid.';

      return;
    }

    // If the end date is not valid, display a validation message.
    if (!DateCommon.IsDateAsStringValid(this.DownloadEndTime)) {
      this.downloadLimitedRangeCommandDateValidationFailure = true;
      this.downloadLimitedRangeCommandDateValidationFailureMessage =
        'The specified end date/time is not valid.';

      return;
    }

    // Construct Date objects for the start date and end date.
    let startDateAsDate: Date = DateCommon.ConvertDateAsStringToDate(
      this.DownloadStartTime
    );
    let endDateAsDate: Date = DateCommon.ConvertDateAsStringToDate(
      this.DownloadEndTime
    );

    // If the start date is after the end date, display a validation message.
    if (startDateAsDate.getTime() >= endDateAsDate.getTime()) {
      this.downloadLimitedRangeCommandDateValidationFailure = true;
      this.downloadLimitedRangeCommandDateValidationFailureMessage =
        'The specified start date/time must be before the end date/time.';

      return;
    }

    // Console log the DownloadLimitedRange object.
    //
    // Note:
    //     this.DownloadLimitedRange.value = 0 (Passages)
    //     this.DownloadLimitedRange.value = 3 (RAW - 2 Mins Max)
    // console.log(this.DownloadLimitedRange);

    // If making a download raw data request.
    if (this.DownloadLimitedRange.value == 3) {
      // Validation: The maximum time frame for a download raw data request is limited to two minutes.
      let secondsBetweenDates: number =
        (endDateAsDate.getTime() - startDateAsDate.getTime()) / 1000;

      if (secondsBetweenDates > 120) {
        this.downloadLimitedRangeCommandDateValidationFailure = true;
        this.downloadLimitedRangeCommandDateValidationFailureMessage =
          'A raw data download request is limited to a two minute maximum interval.';

        return;
      }

      // Construct a download recorded raw data request object.
      let downloadRecordedRawDataRequest: DownloadRecordedRawDataRequest =
        new DownloadRecordedRawDataRequest(
          this.device.uid,
          startDateAsDate,
          endDateAsDate
        );

      // Call the web api service method to create a download recorded raw data request.
      await PigRunService.downloadRecordedRawDataRequest(
        downloadRecordedRawDataRequest
      )
        .then((response) => {
          console.log(
            'QueueCommands.vue. submitDownloadLimitedRangeCommand. Before logging PigRunService.downloadRecordedRawDataRequest response.'
          );
          console.log(response);
        })
        .catch((exception) => {
          console.log(
            'QueueCommands.vue. submitDownloadLimitedRangeCommand. Before logging PigRunService.downloadRecordedRawDataRequest exception.'
          );
          console.error(exception);

          // A web api service exception has occurred.
          //
          // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
          if (exception.response.data != undefined) {
            // Display a web api service exception message with its associated handling instance id.
            this.validationDialogMessage =
              'A queue command service exception has occurred. LogId = [' +
              exception.response.data +
              ']';
            this.validationDialogTitle = 'Queue Command Service Exception';
            this.showValidationMessageDialog = true;
          }

          return;
        });
    }

    // If passages have selected for download.
    if (this.DownloadLimitedRange.value == 0) {
      this.$store.dispatch(
        'postCommand',
        new Command(this.device.uid, CommandType.DownloadRange, new Date(), {
          FileType: this.DownloadLimitedRange.value,
          FileNamesStartDate: startDateAsDate,
          FileNamesEndDate: endDateAsDate,
        })
      );
    }

    console.log(
      'QueueCommands.vue. submitDownloadLimitedRangeCommand. Before closing dialog.'
    );

    // Close the dialog.
    this.commandDialog = false;
  }

  // Mitch - End of the updated implementation for the download within time range command.

  public FoldAntState = {
    label: 'Disabled',
    value: 0,
  };

  public FoldAntStateOptions = [
    {
      label: 'Disabled',
      value: 0,
    },

    {
      label: 'Enabled',
      value: 1,
    },
  ];

  public CellOfflineDownloadState = {
    label: 'Disabled',
    value: 0,
  };

  public CellOfflineDownloadStateOptions = [
    {
      label: 'Disabled',
      value: 0,
    },

    {
      label: 'Enabled',
      value: 1,
    },
  ];

  public DownloadRange = {
    label: 'OFFLINE Psgs via Cell',
    value: 0,
  };

  public DownloadRangeOptions = [
    {
      label: 'OFFLINE Psgs via Cell',
      value: 0,
    },

    {
      label: 'ONLINE Psgs',
      value: 1,
    },

    {
      label: 'OFFLINE Psgs via Sat',
      value: 2,
    },

    {
      label: 'RAW - 5 Mins Max',
      value: 3,
    },
  ];

  public DownloadLimitedRange = {
    label: 'Passages',
    value: 0,
  };

  // Mitch - January 8, 2024.
  //
  // The option to initiate a download request of up to two minutes of raw data is only permitted if the firmware version of the selected device is
  // greater than or equal to 10.71.
  //
  // Note:
  // The firmware version is hard-coded here, rather than stored in configuration, since future releases of firmware will maintain backward compatibility.
  public DownloadRangeLimitedOptions() {
    let returnDownloadRangeLimitedOptions = undefined;

    // If the selected device firmware version not undefined and greater than or equal to 10.71, render the raw option; otherwise, do not.
    if (
      this.device.firmwareVersion != undefined &&
      this.device.firmwareVersion >= 10.71
    ) {
      returnDownloadRangeLimitedOptions = [
        { label: 'Passages', value: 0 },
        { label: 'RAW - 2 Mins Max', value: 3 },
      ];
    } else {
      returnDownloadRangeLimitedOptions = [{ label: 'Passages', value: 0 }];
    }

    return returnDownloadRangeLimitedOptions;
  }

  public mounted() {
    console.log('QueueCommands: mounted()');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
