
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';
import { Command } from '../models/Command';
import { CommandType } from '../models/CommandType';
import { PowerMode } from '../models/PowerMode';
import { RecordModeIridium } from '../models/RecordModeIridium';
import { ConnectionScheduleIridium } from '../models/ConnectionScheduleIridium';
import { StreamEnable } from '../models/StreamEnable';
import { StreamMode } from '../models/StreamMode';
import DateCommon from '../utils/DateCommon';

@Component
export default class QueueIridiumCommands extends Vue {
  public commandDialog = false;

  get device(): Device {
    return this.$store.state.selectedDevice;
  }

  get queuedCommands(): Command[] {
    return this.$store.state.command.commands;
  }

  public selectedPowerMode: string = PowerMode.Active;
  public powerModeOptions = PowerMode.getModes();

  public selectedConnectionSchedule: string =
    ConnectionScheduleIridium.ConnectEvery30Minutes;
  public connectionScheduleOptions = ConnectionScheduleIridium.getModes();

  public selectedRecordMode: string = RecordModeIridium.Off;
  public recordModeOptions = RecordModeIridium.getModes();

  public DownloadStartTime = '';
  public DownloadEndTime = '';

  // these get changed into string on input changes
  public fsZero: number | string = 0;
  public fsOne: number | string = 10;
  public fsTwo: number | string = 10;
  public fsThree: number | string = 10;
  public enabledZero = true;
  public enabledOne = true;
  public enabledTwo = false;
  public enabledThree = false;

  public sensitivityZero = {
    label: 'Medium',
    value: 3,
  };

  public sensitivityOne = {
    label: 'Medium',
    value: 3,
  };

  public sensitivityTwo = {
    label: 'Medium',
    value: 3,
  };

  public sensitivityThree = {
    label: 'Medium',
    value: 3,
  };

  public sensitivityOptions = [
    {
      label: 'Ultra Low',
      value: 0,
    },

    {
      label: 'Very Low',
      value: 1,
    },

    {
      label: 'Low',
      value: 2,
    },

    {
      label: 'Medium',
      value: 3,
    },

    {
      label: 'High',
      value: 4,
    },

    {
      label: 'Very High',
      value: 5,
    },

    {
      label: 'Ultra High',
      value: 6,
    },
  ];

  public passageSizeOptions = [
    {
      label: '10 sec',
      value: 0,
    },

    {
      label: '20 sec',
      value: 1,
    },
    // {
    //     label: '30 sec',
    //     value: 2,
    // },
  ];

  public selectedPassageSize = {
    label: '10 sec',
    value: 0,
  };

  public selectedAntFoldState = {
    label: 'Disabled',
    value: 0,
  };

  public selectedGeoGain = {
    label: 'Medium',
    value: 1,
  };

  public selectedOFFDwnState = {
    label: 'Disabled',
    value: 0,
  };

  public FoldAntOptions = [
    {
      label: 'Disabled',
      value: 0,
    },

    {
      label: 'Enabled',
      value: 1,
    },
  ];

  public GeoGainOptions = [
    {
      label: 'Low',
      value: 0,
    },

    {
      label: 'Medium',
      value: 1,
    },

    {
      label: 'High',
      value: 2,
    },

    {
      label: 'Very High',
      value: 3,
    },
  ];

  public OFFDwnStateOptions = [
    {
      label: 'Disabled',
      value: 0,
    },

    {
      label: 'Enabled',
      value: 1,
    },
  ];

  public passageStateOptions = [
    {
      label: 'Detection Only',
      value: 0,
    },

    {
      label: 'Detection & GEO',
      value: 1,
    },
    // {
    //     label: 'MAG/ELF & No GEO',
    //     value: 2,
    // },
    // {
    //     label: 'MAG/ELF & GEO',
    //     value: 3,
    // },
  ];

  public selectedPassageState = {
    label: 'Detection Only',
    value: 0,
  };

  public isDetectionCountReset = false;
  public isDeviceRestart = false;
  public isClearOFFLINE = false;
  public isErrorFlagReset = false;
  public ErrorMsg = false;
  public selectedCommands: Command[] = [];
  public FirmwareVersionMajor = 0;
  public FirmwareVersionMinor = 0;
  public FirmwareVersion = 0;

  public columns = [
    {
      name: 'timeStampSent',
      field: 'timeStampSent',
      label: 'Sent',
      align: 'left',
    },
    {
      name: 'commandType',
      field: this.showType,
      align: 'center',
      label: 'Type',
    },
    { name: 'parameters', field: this.showPayload, label: 'Parameters' },
    { name: 'commandId', field: 'commandId', label: 'Action' },
  ];

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'queueCommandsIridiumShow') {
      this.showDialog();
    }

    if (action.type === 'queueCommandsClose') {
      this.closeDialog();
    }
  });

  public isValidFrequency(model: number) {
    return !(model >= 10 && model <= 30);
  }

  public toProperWord(text: string) {
    // camel case to proper words
    return (
      text.charAt(0).toUpperCase() + text.replace(/([A-Z])/g, ' $1').slice(1)
    );
  }

  public sensitivityText(sensitivity: number) {
    if (sensitivity === 0) {
      return 'UL';
    }

    if (sensitivity === 1) {
      return 'VL';
    }

    if (sensitivity === 2) {
      return 'L';
    }

    if (sensitivity === 3) {
      return 'M';
    }

    if (sensitivity === 4) {
      return 'H';
    }

    if (sensitivity === 5) {
      return 'VH';
    }

    if (sensitivity === 6) {
      return 'UH';
    }

    return '';
  }

  public showPayload(row: any) {
    // row type==Command, but I don't have the payload defined so there may be type issues
    if (!row.parameters) {
      return '';
    }

    if (row.commandType === CommandType.IridiumSettings) {
      return 'Iridium Settings';
    }

    if (row.commandType === CommandType.IridiumOfflineDownload) {
      return '';
    }

    if (row.commandType === CommandType.IridiumExitOfflineDownload) {
      return '';
    }

    return row.parameters;
  }

  public showType(row: any) {
    if (row.commandType === CommandType.IridiumOfflineDownload) {
      return 'Iridium Offline Download';
    }

    if (row.commandType === CommandType.IridiumExitOfflineDownload) {
      return 'Iridium Exit Download';
    }

    return this.toProperWord(row.commandType);
  }

  public onDeleteCommand(commandId: number) {
    this.$store.dispatch('deleteCommand', commandId);
  }

  public newcommandDisplay = false;

  public updatedialogfm() {
    if (this.device.firmwareVersion) {
      this.FirmwareVersion = this.device.firmwareVersion;
      this.FirmwareVersionMajor = Math.floor(this.device.firmwareVersion);
      this.FirmwareVersionMinor =
        (this.device.firmwareVersion - this.FirmwareVersionMajor) * 10;

      if (this.device.firmwareVersion < 10.29) {
        this.powerModeOptions = PowerMode.getModesLimited();
      } else {
        this.powerModeOptions = PowerMode.getModes();
      }

      if (this.device.firmwareVersion > 2.4) {
        this.newcommandDisplay = true;
      } else {
        this.newcommandDisplay = false;
      }
    } else {
      this.newcommandDisplay = false;
    }
  }

  public showDialog() {
    this.updatedialogfm();

    this.commandDialog = true;

    // clear any previous values, set to defaults
    this.selectedPowerMode = PowerMode.Active;
    this.selectedConnectionSchedule =
      ConnectionScheduleIridium.ConnectEvery30Minutes;
    this.selectedRecordMode = RecordModeIridium.On;

    if (this.device.lastFrequency0Setting != null) {
      this.fsZero = this.device.lastFrequency0Setting;
    } else {
      this.fsZero = 0;
    }

    if (this.device.lastFrequency1Setting != null) {
      this.fsOne = this.device.lastFrequency1Setting;
    } else {
      this.fsOne = 22;
    }

    if (this.device.lastFrequency2Setting != null) {
      this.fsTwo = this.device.lastFrequency2Setting;
    } else {
      this.fsTwo = 22;
    }

    if (this.device.lastFrequency3Setting != null) {
      this.fsThree = this.device.lastFrequency3Setting;
    } else {
      this.fsThree = 22;
    }

    if (this.device.lastEnable0 != null) {
      this.enabledZero = this.device.lastEnable0;
    } else {
      this.enabledZero = true;
    }

    if (this.device.lastEnable1 != null) {
      this.enabledOne = this.device.lastEnable1;
    } else {
      this.enabledOne = true;
    }

    if (this.device.lastEnable2 != null) {
      this.enabledTwo = this.device.lastEnable2;
    } else {
      this.enabledTwo = false;
    }

    if (this.device.lastEnable3 != null) {
      this.enabledThree = this.device.lastEnable3;
    } else {
      this.enabledThree = false;
    }

    if (this.device.lastSensitivity0 != null) {
      this.sensitivityZero =
        this.sensitivityOptions[this.device.lastSensitivity0];
    } else {
      this.sensitivityZero = { label: 'High', value: 4 };
    }

    if (this.device.lastSensitivity1 != null) {
      this.sensitivityOne =
        this.sensitivityOptions[this.device.lastSensitivity1];
    } else {
      this.sensitivityOne = { label: 'High', value: 4 };
    }

    if (this.device.lastSensitivity2 != null) {
      this.sensitivityTwo =
        this.sensitivityOptions[this.device.lastSensitivity2];
    } else {
      this.sensitivityTwo = { label: 'High', value: 4 };
    }

    if (this.device.lastSensitivity3 != null) {
      this.sensitivityThree =
        this.sensitivityOptions[this.device.lastSensitivity3];
    } else {
      this.sensitivityThree = { label: 'High', value: 4 };
    }

    this.selectedPassageSize = { label: '10 sec', value: 0 };
    this.selectedPassageState = { label: 'Detection Only', value: 0 };

    this.isDetectionCountReset = false;
    this.isDeviceRestart = false;
    this.isErrorFlagReset = false;
    this.isClearOFFLINE = false;

    this.$store.dispatch('getCommands', this.device.uid);
  }

  public closeDialog() {
    this.commandDialog = false;
    this.ErrorMsg = false;
    this.DownloadStartTime = '';
    this.DownloadEndTime = '';
  }

  // params for commands that are not enabled yet
  //   streamEnabled:
  //     type: boolean
  //     description: Enable steaming mode
  //   newPort:
  //     type: integer
  //     description: New port number for the AGM
  //   newIP:
  //     type: string
  //     description: New IP address

  public submitIridiumSettings() {
    console.log(
      'QueueIridiumCommands.vue. submitIridiumSettings. Enter method.'
    );

    const params: any = {
      frequency0: parseInt(this.fsZero as string, 10),
      frequency1: parseInt(this.fsOne as string, 10),
      frequency2: parseInt(this.fsTwo as string, 10),
      frequency3: parseInt(this.fsThree as string, 10),
      sensitivity0: this.sensitivityZero.value,
      sensitivity1: this.sensitivityOne.value,
      sensitivity2: this.sensitivityTwo.value,
      sensitivity3: this.sensitivityThree.value,
      enable0: this.enabledZero,
      enable1: this.enabledOne,
      enable2: this.enabledTwo,
      enable3: this.enabledThree,
    };

    if (this.selectedPowerMode.length) {
      params.powerMode = this.powerModeOptions.indexOf(this.selectedPowerMode);
    }

    if (
      this.selectedPowerMode === PowerMode.Hibernate ||
      this.selectedPowerMode === PowerMode.LowPower
    ) {
      params.connectSchedule = this.connectionScheduleOptions.indexOf(
        this.selectedConnectionSchedule
      );
    }

    if (this.selectedRecordMode.length) {
      params.recordMode = this.recordModeOptions.indexOf(
        this.selectedRecordMode
      );
    }

    params.detectCountReset = this.isDetectionCountReset;
    params.restartUnit = this.isDeviceRestart;
    params.psgSize = this.selectedPassageSize.value;
    params.psgState = this.selectedPassageState.value;

    params.UnfoldedAntennaDetectorEnable = this.selectedAntFoldState.value;
    params.GeoMode = this.selectedGeoGain.value;
    params.OfflineDownloadState = this.selectedOFFDwnState.value;
    params.ClearIridiumOfflineFiles = this.isClearOFFLINE;
    params.FileNamesStartDate = null;
    params.FileNamesEndDate = null;

    console.log(
      new Command(
        this.device.uid,
        CommandType.IridiumSettings,
        new Date(),
        params
      )
    );

    this.$store.dispatch(
      'postCommand',
      new Command(
        this.device.uid,
        CommandType.IridiumSettings,
        new Date(),
        params
      )
    );
  }

  public submitDetectionCountReset() {
    console.log(
      'QueueIridiumCommands.vue. submitDetectionCountReset. Enter method.'
    );

    this.$store.dispatch(
      'postCommand',
      new Command(
        this.device.uid,
        CommandType.DetectionCountReset,
        new Date(),
        {}
      )
    );
  }

  public submitDeviceRestart() {
    console.log('QueueIridiumCommands.vue. submitDeviceRestart. Enter method.');

    this.$store.dispatch(
      'postCommand',
      new Command(this.device.uid, CommandType.DeviceRestart, new Date(), {})
    );
  }

  public submitIridiumDownload() {
    console.log(
      'QueueIridiumCommands.vue. submitIridiumDownload. Enter method.'
    );

    // If the start date is not valid, display a validation message.
    if (!DateCommon.IsDateAsStringValid(this.DownloadStartTime)) {
      // Display date validation message.
      this.ErrorMsg = true;

      return;
    }

    // If the end date is not valid, display a validation message.
    if (!DateCommon.IsDateAsStringValid(this.DownloadEndTime)) {
      // Display date validation message.
      this.ErrorMsg = true;

      return;
    }

    // Construct Date objects for the start date and end date.
    let startDateAsDate: Date = DateCommon.ConvertDateAsStringToDate(
      this.DownloadStartTime
    );
    let endDateAsDate: Date = DateCommon.ConvertDateAsStringToDate(
      this.DownloadEndTime
    );

    // If the start date is after the end date, display a validation message.
    if (startDateAsDate.getTime() >= endDateAsDate.getTime()) {
      // Display date validation message.
      this.ErrorMsg = true;

      return;
    }

    let secondsBetweenDates: number =
      (endDateAsDate.getTime() - startDateAsDate.getTime()) / 1000;

    // Validation: The maximum time frame for a download passage request over Iridium is limited to 10 minutes.
    if (secondsBetweenDates > 600) {
      // Display date validation message.
      this.ErrorMsg = true;

      return;
    }

    // Construct the command parameters.
    const params: any = {
      FileNamesStartDate: startDateAsDate,
      FileNamesEndDate: endDateAsDate,
    };

    // Dispatch the postCommand action.
    this.$store.dispatch(
      'postCommand',
      new Command(
        this.device.uid,
        CommandType.IridiumOfflineDownload,
        new Date(),
        params
      )
    );
  }

  // Mitch - November 24, 2023.
  //
  // Previous implementation of the submitIridiumDownload handler.
  /*
    public submitIridiumDownload() 
    {
        var StartDate:Date;
        var EndDate:Date;

        if(this.DownloadStartTime == '' || this.DownloadEndTime == '')
        {
            // Display Error Message
            this.ErrorMsg = true;
        }
        else 
        {
                // We need to convert the time from String to Date Type
                this.ErrorMsg = false;
                StartDate = this.ConvertStringTimetoDate(this.DownloadStartTime);
                EndDate = this.ConvertStringTimetoDate(this.DownloadEndTime);
                // Now we need to send the command to the WebAPI
                const params: any = {
                    FileNamesStartDate: StartDate,
                    FileNamesEndDate: EndDate,
                };
                this.$store.dispatch('postCommand', new Command(this.device.uid, CommandType.IridiumOfflineDownload, new Date(), params));
        }
    }

    public aConvertStringTimetoDate(acttime:string)
    {
        var hour,min,sec,year,month,day,milisec;
        var StartDate:Date;
        year = acttime[0]+acttime[1]+acttime[2]+acttime[3];
        month = acttime[5]+acttime[6];
        day = acttime[8]+acttime[9];
        hour = acttime[11]+acttime[12];
        min = acttime[14]+acttime[15];
        sec = acttime[17]+acttime[18];
        milisec = "000";

        StartDate = new Date();
        StartDate.setUTCFullYear(parseInt(year));
        StartDate.setUTCMonth((parseInt(month)-1));
        StartDate.setUTCDate(parseInt(day));
        StartDate.setUTCHours(parseInt(hour));
        StartDate.setUTCMinutes(parseInt(min));
        StartDate.setUTCSeconds(parseInt(sec));
        StartDate.setUTCMilliseconds(0);
        return StartDate;
    }
    */

  public submitIridiumExitDownload() {
    this.$store.dispatch(
      'postCommand',
      new Command(
        this.device.uid,
        CommandType.IridiumExitOfflineDownload,
        new Date()
      )
    );
  }

  public mounted() {
    console.log('QueueIridiumCommands.vue. mounted. Enter method.');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
