import Vue from 'vue';
import Vuex from 'vuex';
import { Device } from '../../models/Device';
import { Passage } from '../../models/Passage';
import DeviceService from '../../services/DeviceService';
import PigRunService from '../../services/PigRunService';
import { UpdatePassageTimeStampRequest } from '@/models/ReportManager/UpdatePassageTimeStampRequest';

Vue.use(Vuex);

export default {
  state: {},
  mutations: {},
  actions: {
    setIsIridium(context: any, payload: any) {
      // this updated directly from a signalR message
      const oldDevice = context.rootState.devices.find(
        (old: Device) => old.uid === payload.uid
      );
      if (oldDevice && oldDevice.isIridium !== payload.isIridium) {
        context.dispatch('updateDeviceIcon', {
          uid: oldDevice.uid,
          isConnected: oldDevice.isConnected,
          isIridium: payload.isIridium,
        });
      }
      context.commit('SET_DEVICE_IS_IRIDIUM', payload, { root: true });
    },
    setAutoDownload(context: any, payload: any) {
      DeviceService.autoDownload(payload.uid, payload.isAutoDownload)
        .then((response) => {
          // console.log(response);
          context.commit('SET_DEVICE_AUTODOWNLOAD', payload, { root: true });
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },
    setRecordMode(context: any, payload: any) {
      // this updated directly from a signalR message
      context.commit('SET_DEVICE_RECORD_MODE', payload, { root: true });
    },

    updatePassageTimeStamps(context: any, payload: any) {
      console.log('DeviceStore.ts. Action = [updatePassageTimeStamps].');
      console.log(
        'DeviceStore.ts. Action = [updatePassageTimeStamps]. context.rootState.passageDialogSource = [' +
          context.rootState.passageDialogSource +
          '].'
      );

      const promiseArray: any[] = [];
      let uid = 0;

      if (context.rootState.passageDialogSource === 'selected_passage') {
        context.rootState.selectedPassages.forEach((passage: Passage) => {
          uid = context.rootState.selectedPassages[0].uid;

          console.log(
            'DeviceStore.ts. updatePassageTimeStamps. section = [selected_passage]. uid = [' +
              uid +
              '].'
          );

          promiseArray.push(
            DeviceService.updateTimeStampByPassageId(
              uid,
              passage.passageId!,
              payload.newPassageTime
            )
          );
        });
      } else {
        if (context.rootState.passageDialogSource === 'historic_data') {
          uid = context.rootState.selectedPassages[0].uid;
        }

        if (context.rootState.passageDialogSource === 'verify_passage') {
          uid = context.rootState.uidToUpdate;
        }

        context.rootState.passageIdsToUpdate.forEach((passageId: number) => {
          promiseArray.push(
            DeviceService.updateTimeStampByPassageId(
              uid,
              passageId,
              payload.newPassageTime
            )
          );
        });
      }

      console.log(
        'DeviceStore.ts. updatePassageTimeStamps. uid = [' + uid + '].'
      );

      Promise.all(promiseArray)
        .then((response) => {
          context.dispatch('notify', 'Success!');
          context.dispatch('getHistory', { uid, type: 'passage' });
          context.dispatch('getDevice', uid);
          context.commit(
            'SET_SELECTED_PASSAGE_TIMESTAMP',
            payload.newPassageTimeDisplay
          );
          context.dispatch('updatedTimeStampData', {
            uid,
            passageIds: context.rootState.passageIdsToUpdate,
            updatedTimeStamp: payload.newPassageTimeDisplay,
          });
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },

    // An action dispatched by the PassageDialog.vue component to edit a passage time stamp.
    async updatePassageTimeStampFromPassageDialogComponent(
      context: any,
      payload: any
    ) {
      console.log(
        'DeviceStore.ts. Action = [updatePassageTimeStampFromPassageDialogComponent].'
      );

      // Get the payload values.
      const passageId: number = payload.passageId;
      const updatePassageTimeStampTargetValue: Date =
        payload.updatePassageTimeStampTargetValue;

      // Construct an UpdatePassageTimeStampRequest object.
      const updatePassageTimeStampRequest: UpdatePassageTimeStampRequest =
        new UpdatePassageTimeStampRequest(
          passageId,
          updatePassageTimeStampTargetValue
        );

      await PigRunService.updatePassageTimeStamp(updatePassageTimeStampRequest)
        .then((response) => {
          console.log(response);
        })
        .catch((exception) => {
          console.error(exception);
        });

      // let getDeviceUidResponse: any;
      //
      // await DeviceService.getDeviceUid(passageId)
      // .then((response) =>
      // {
      //     console.log(response);
      //
      //     getDeviceUidResponse = response.data;
      // })
      // .catch((exception) =>
      // {
      //     console.log(exception);
      // });
      //
      // let uid = getDeviceUidResponse.uid;

      // Dispatch the same set of actions as updatePassageTimeStamps above.
      // context.dispatch('getHistory', { uid, type: 'passage' });
      // context.dispatch('getDevice', uid);
      // context.commit('SET_SELECTED_PASSAGE_TIMESTAMP', updatePassageTimeStampTargetValue);
    },

    // Mitch test - (end)

    updatedTimeStampData(context: any, payload: any) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    getIhabsDevice(context: any, payload: any) {
      DeviceService.getIhabsDevice(payload.uid)
        .then((response) => {
          // console.log(response);
          // context.commit('SET_DEVICE_AUTODOWNLOAD', payload, { root: true });
        })
        .catch((error) => {
          console.error(error);
          context.dispatch('notify', error);
        });
    },
  },
};
