export class ConnectionScheduleIridium {
  public static ConnectEvery30Minutes = 'Connect Every 30 Minutes';
  public static ConnectEvery1Hour = 'Connect Every 1 Hour';
  public static ConnectEvery2Hours = 'Connect Every 2 Hours';
  public static ConnectEvery4Hours = 'Connect Every 4 Hours';
  public static ConnectEvery8Hours = 'Connect Every 8 Hours';
  public static ConnectEvery12Hours = 'Connect Every 12 Hours';
  public static ConnectEvery16Hours = 'Connect Every 16 Hours';
  public static ConnectEvery24Hours = 'Connect Every 24 Hours';
  public static getModes() {
    return [
      this.ConnectEvery30Minutes, // 0
      this.ConnectEvery1Hour, // 1
      this.ConnectEvery2Hours, // 2
      this.ConnectEvery4Hours, // 3
      this.ConnectEvery8Hours, // 4
      this.ConnectEvery12Hours, // 5
      this.ConnectEvery16Hours, // 6
      this.ConnectEvery24Hours, // 7
    ];
  }
  public static byIdx(i: number) {
    return [
      this.ConnectEvery30Minutes,
      this.ConnectEvery1Hour,
      this.ConnectEvery2Hours,
      this.ConnectEvery4Hours,
      this.ConnectEvery8Hours,
      this.ConnectEvery12Hours,
      this.ConnectEvery16Hours,
      this.ConnectEvery24Hours,
    ][i];
  }
  public static toType(i: number) {
    return [
      'every30mins', // 0
      'every1hrs', // 1
      'every2hrs', // 2
      'every4hrs', // 3
      'every8hrs', // 4
      'every12hrs', // 5
      'every16hrs', // 6
      'every24hrs', // 7
    ][i];
  }
  public static toString(s: string) {
    const idx = [
      'every30mins', // 0
      'every1hrs', // 1
      'every2hrs', // 2
      'every4hrs', // 3
      'every8hrs', // 4
      'every12hrs', // 5
      'every16hrs', // 6
      'every24hrs', // 7
    ].indexOf(s);
    if (idx !== -1) {
      return this.byIdx(idx);
    } else {
      return '';
    }
  }
}
