// PassageVerificationRequest.
//
// Class representing a passage verification request.
export class PassageVerificationRequest {
  // ElfId.
  public elfId: number | undefined;

  // GeoId.
  public geoId: number | undefined;

  // isVerified (maps to Passage.IsVerified).
  public isVerified: boolean;

  // MagId.
  public magId: number | undefined;

  // Constructor.
  public constructor(
    elfId: number,
    geoId: number,
    magId: number,
    isVerified: boolean
  ) {
    this.elfId = elfId;
    this.geoId = geoId;
    this.magId = magId;
    this.isVerified = isVerified;
  }
}
